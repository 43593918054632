export const Figures = (() => {
	const resize = () => {
		let svgClass: NodeListOf<HTMLElement> = document.querySelectorAll('.js-figures-wrapper')
		for (let i = 0; i < svgClass.length; i++) {
			const currentFigures: HTMLElement = svgClass[i]
			if (!currentFigures) continue
			if (!currentFigures.parentElement) continue

			let figuresHeight: number = currentFigures.parentElement.offsetHeight
			if (currentFigures.getAttribute('data-head')) {
				const head = document.querySelector(currentFigures.getAttribute('data-head'))
				if (head) {
					figuresHeight -= head.offsetHeight
				}
			}

			if (currentFigures.getAttribute('data-footer')) {
				const footer = document.querySelector(currentFigures.getAttribute('data-footer'))
				if (footer) {
					figuresHeight -= footer.offsetHeight
				}
			}
			if (figuresHeight < 0) {
				figuresHeight = 100
			}
			currentFigures.style.height = figuresHeight + 'px'
		}
	}

	return {
		resize,
	}
})()
