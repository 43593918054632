import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.handler },
		true,
	)

const getTargets = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.target },
		true,
	)

const getMoves = (id: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.move, value: id },
		true,
	)

const getHandler = (id: string) => createSelector<HTMLElement>({ selector: selector.handler, value: id })

const getTarget = (id: string) => createSelector<HTMLElement>({ selector: selector.target, value: id })

export const selectors = {
	getHandlers,
	getHandler,
	getTarget,
	getTargets,
	getMoves,
}
