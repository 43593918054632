import $ from 'jquery'
import { useEvent } from 'hooks'
import { debounce } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const Search = (() => {
	const handleTarget = (scope: HTMLElement) => {
		const input = useEvent<Event>(scope, 'input')
		const link = scope.getAttribute(constant.selector.target)
		if (!link) return

		input.register(
			debounce(({ e }: any) => {
				const { value } = e.target as HTMLInputElement

				// @ts-ignore
				$.nette.ajax({
					method: 'POST',
					url: link,
					dataType: 'json',
					data: {
						search: value,
					},
				})
			}),
		)
	}

	const initTargets = () => {
		const targets = selectors.getTargets()

		for (let i = 0, { length } = targets; i < length; i++) {
			const t = targets[i]
			handleTarget(t)
		}
	}

	const init = () => {
		initTargets()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
