import Sortable from 'sortablejs'

import { useEvent, useState } from 'hooks'
import {
	getHierarchyColumns,
	getHierarchyColumnsContainer,
	getHierarchyItemWrappers,
	getHierarchySelectors,
	getItemIcon,
} from 'selectors'
import $ from 'jquery'
import { module } from 'constant'
import { addClass, removeClass } from '../../utils/class'
import { validateInput, validateSelect } from '../../utils/inputs'

const { hierarchy } = module.creator

type State = {
	questionnaireSelect?: HTMLSelectElement
	hierarchyNameInput?: HTMLInputElement
	questionnaireColumnsContainer?: HTMLElement
	hierarchyColumnsContainer?: HTMLElement
	hierarchySubmitButton?: HTMLElement
}

export const Hierarchy = () => {
	const { state, setState } = useState<State>()

	const handleSortable = () => {
		const { questionnaireColumnsContainer, hierarchyColumnsContainer } = state
		if (!questionnaireColumnsContainer || !hierarchyColumnsContainer) return

		const sortableColumns = new Sortable(questionnaireColumnsContainer, {
			group: 'shared',
			animation: 150,
			onChange: event => {
				const icon = getItemIcon(event.item)
				if (icon) {
					icon.remove()
				}
			},
		})

		const sortableHierarchyColumns = new Sortable(hierarchyColumnsContainer, {
			group: 'shared',
			animation: 150,
			onChange: event => {
				const items = getHierarchyItemWrappers(event.to)
				const itemsLength = items.length
				for (let i = 0; i < itemsLength; i++) {
					const item = items[i]
					const icon = getItemIcon(item)
					const isAtEnd = i === itemsLength - 1
					if (!isAtEnd && !icon) {
						const el = document.createElement('i')
						el.className = hierarchy.selector.icon.replace(/\./g, ' ')
						item.appendChild(el)
					} else if (isAtEnd && icon) {
						icon.remove()
					}
				}
			},
		})

		setState({ sortableColumns, sortableHierarchyColumns })
	}

	const validateHierarchy = () => {
		const { questionnaireSelect, hierarchyNameInput } = state
		if (!questionnaireSelect || !hierarchyNameInput) return null
		const questionnaireValue = validateSelect(questionnaireSelect)
		const nameValue = validateInput(hierarchyNameInput)
		const questionnaireColumns = validateHierarchyColumn()
		return { questionnaireValue, nameValue, questionnaireColumns }
	}

	const validateHierarchyColumn = () => {
		const hierarchyColumns = getHierarchyColumns()
		const hierarchyColumnsContainer = getHierarchyColumnsContainer()
		if (!hierarchyColumnsContainer) return null
		if (!hierarchyColumns || hierarchyColumns.length === 0) {
			addClass(hierarchyColumnsContainer, 'invalid')
			return null
		}
		removeClass(hierarchyColumnsContainer, 'invalid')
		const values = []
		for (let i = 0; i < hierarchyColumns.length; i++) {
			values.push(hierarchyColumns[i].getAttribute(hierarchy.selector.itemIdAttribute))
		}
		return values
	}

	const initQuestionnaireSelectEvents = () => {
		const { questionnaireSelect } = state

		if (!questionnaireSelect) return
		handleQuestionnaireSelect(questionnaireSelect)
	}

	const handleQuestionnaireSelect = (select: HTMLSelectElement) => {
		const change = useEvent(select, 'change')
		change.register(() => {
			$.nette.ajax({
				method: 'POST',
				url: select.getAttribute(hierarchy.selector.questionnaireHandler),
				data: { questionnaireId: select.value },
			})
		})
	}

	const initSubmitHandler = () => {
		const { hierarchySubmitButton } = state
		if (!hierarchySubmitButton) return
		const click = useEvent(hierarchySubmitButton, 'click')
		click.register(() => {
			const values = validateHierarchy()
			if (!values) return
			const { questionnaireValue, nameValue, questionnaireColumns } = values
			if (questionnaireValue !== null && nameValue !== null && questionnaireColumns !== null) {
				$.nette.ajax({
					method: 'POST',
					url: hierarchySubmitButton.getAttribute(hierarchy.selector.submitHandler),
					data: {
						questionnaireId: questionnaireValue,
						hierarchyName: nameValue,
						hierarchyColumnsIds: questionnaireColumns,
					},
				})
			}
		})
	}

	const handleInitSelectors = () => {
		setState({
			...getHierarchySelectors(),
		})
	}

	const init = () => {
		handleInitSelectors()
		handleSortable()
		initQuestionnaireSelectEvents()
		initSubmitHandler()
	}

	return {
		init,
	}
}
