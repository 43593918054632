import $ from 'jquery'
import { useEvent, useState } from 'hooks'
import { fileManagerSelector } from 'selectors'
import { cn } from 'utils'
import { modifier } from 'constant'

export const FileManager = (() => {
	const { state, setState } = useState()

	const handleFolderEvent = (scope: HTMLElement) => {
		const click = useEvent(scope, 'click')

		click.register(() => {
			const { parentNode } = scope
			const treeId = scope.getAttribute('data-tree-id')

			if (!parentNode || !treeId) return
			const folderIcon = fileManagerSelector.getFolderIcon(parentNode as HTMLElement)
			const tree = fileManagerSelector.getFolderTree(parentNode as HTMLElement, treeId)

			if (!folderIcon || !tree) return
			if (scope.getAttribute('data-is-opened') === 'true') {
				cn.removeClass(folderIcon, modifier.active)
				cn.removeClass(folderIcon, 'fa-folder-open')
				cn.addClass(folderIcon, 'fa-folder')
				scope.setAttribute('data-is-opened', 'false')
				tree.textContent = ''
			} else {
				const url = scope.getAttribute('data-ajax-url')
				const path = scope.getAttribute('data-path')

				if (!url || !path) return
				tree.innerHTML = '<div class="text-center"><i class="fa fa-spinner fa-spin"></i></div>'

				// @ts-ignore
				$.nette.ajax({
					url,
					method: 'POST',
					data: { path },
					complete(response: object) {
						// @ts-ignore
						tree.innerHTML = response.responseJSON.tree
						cn.removeClass(folderIcon, 'fa-folder')
						cn.addClass(folderIcon, 'fa-folder-open')
						cn.addClass(folderIcon, modifier.active)
						scope.setAttribute('data-is-opened', 'true')
						initFolders(fileManagerSelector.getFolders(tree))
					},
				})
			}
		})
	}

	const initFolders = (folders: NodeListOf<HTMLElement>) => {
		if (!folders) return
		const { length } = folders

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const f = folders[i]

			if (!f) continue
			handleFolderEvent(f)
		}
	}

	const init = () => {
		setState({
			...fileManagerSelector.getFileManagerSelectors(),
		})
		initFolders(state.folders)
	}

	return {
		init,
	}
})()
