import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getAllTargets = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: `.${selector.target}` },
		true,
	)

const getTarget = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: `.${selector.target}` })

export const selectors = {
	getAllTargets,
	getTarget,
}
