import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getStream = (value: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.stream, value },
		true,
	)

const getStreams = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.stream },
		true,
	)

export const selectors = {
	getStream,
	getStreams,
}
