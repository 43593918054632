export const constant = {
	selector: {
		edit: 'js-m-edit-mode',
		input: 'js-m-edit-mode-input',
		before: 'data-m-edit-mode-before',
		id: 'data-m-edit-mode-id',
		value: 'data-m-edit-mode-value',
		handler: 'data-m-edit-mode-handler',
		active: 'data-m-edit-mode-active',
		target: 'data-m-edit-mode',
		url: 'data-m-edit-mode-url',
		deactivation: 'js-deactivation-checkbox',
	},
}
