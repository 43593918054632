import M from 'materialize-css'
import $ from 'jquery'

import { useEvent, useState } from 'hooks'
import {
	getDeleteTargetButtonsSelector,
	getEditInfoElement,
	getNoTargetInfo,
	getQuestionsOverviewSelectors,
	getTargetDatePickersSelector,
	getTargetEdit,
	getTargetValueSelector,
	getValueElementOfMetadataItem,
} from 'selectors'
import { module } from 'constant'
import { cn } from 'utils'
import { addClass, removeClass } from '../../utils/class'
import { validateInput } from '../../utils/inputs'

const { questionsOverview } = module.creator

type State = {
	questionsOverviewCollapsible?: HTMLElement
	languageButton?: HTMLElement
	exportButton?: HTMLElement
	metadataEditIcons?: NodeListOf<HTMLElement>
	editMode: boolean
	activeSelect?: M.FormSelect
	targetDatePickers?: NodeListOf<HTMLInputElement>
	createTargetButtons?: NodeListOf<HTMLElement>
	saveTargetButtons?: NodeListOf<HTMLElement>
	deleteTargetButtons?: NodeListOf<HTMLElement>
}

export const QuestionsOverview = () => {
	const { state, setState } = useState<State>()
	setState({ editMode: false })

	const initCollapsible = () => {
		const { questionsOverviewCollapsible } = state
		if (!questionsOverviewCollapsible) return

		M.Collapsible.init(questionsOverviewCollapsible)
	}

	const initLanguageButton = () => {
		const { languageButton } = state
		if (!languageButton) return

		M.Dropdown.init(languageButton, { coverTrigger: false })
	}

	const initExportButton = () => {
		const { exportButton } = state
		if (!exportButton) return

		M.Dropdown.init(exportButton, { coverTrigger: false })
	}

	const handleEditIconClick = () => {
		const { metadataEditIcons } = state
		if (!metadataEditIcons) return

		for (let i = 0; i < metadataEditIcons.length; i++) {
			const icon = metadataEditIcons[i]
			const click = useEvent(icon, 'click')
			click.register(() => {
				const metadataItem = icon.parentElement
				if (!metadataItem) return
				toggleEditMode(icon)
				const valueElement = getValueElementOfMetadataItem(metadataItem)
				const possibleValues = icon.getAttribute(questionsOverview.selector.editValues)
				const editComponent = createEditComponent(
					valueElement.getAttribute(questionsOverview.selector.valueAttribute),
					possibleValues,
				)
				valueElement.replaceWith(editComponent)
				if (editComponent.tagName === 'SELECT') setState({ activeSelect: M.FormSelect.init(editComponent) })
				editComponent.focus()
				icon.replaceWith(createSubmitButtons(icon, valueElement, editComponent))
			})
		}
	}

	const toggleEditMode = (icon: HTMLElement) => {
		setState({ editMode: !state.editMode })
		toggleIcons()
		toggleEditInfo(icon)
	}

	const toggleIcons = () => {
		const { metadataEditIcons } = state
		if (!metadataEditIcons) return
		for (let i = 0; i < metadataEditIcons.length; i++) {
			const icon = metadataEditIcons[i]
			if (state.editMode) {
				cn.addClass(icon, 'hidden')
			} else {
				cn.removeClass(icon, 'hidden')
			}
		}
	}
	const toggleEditInfo = (icon: HTMLElement) => {
		const collapsibleItem = icon.closest(questionsOverview.selector.questionsOverviewCollapsibleItem) as HTMLElement
		if (!collapsibleItem) return

		const editInfoElement = getEditInfoElement(collapsibleItem)
		if (!editInfoElement) return

		if (state.editMode) {
			cn.removeClass(editInfoElement, 'hidden')
		} else {
			cn.addClass(editInfoElement, 'hidden')
		}
	}

	const createSubmitButtons = (
		icon: HTMLElement,
		valueElement: HTMLElement,
		editComponent: HTMLInputElement | HTMLSelectElement,
	): HTMLElement => {
		const wrapper = document.createElement('DIV') as HTMLDivElement
		wrapper.className = 'action-buttons'
		const confirmButton = createButton('fas fa-check', 'green')
		const confirmClick = useEvent(confirmButton, 'click')
		confirmClick.register(() => {
			// @ts-ignore
			$.nette.ajax({
				method: 'POST',
				url: icon.getAttribute(questionsOverview.selector.handle),
				data: { value: editComponent.value },
				success: (payload: any) => {
					if (payload.success) {
						valueElement.setAttribute(questionsOverview.selector.valueAttribute, editComponent.value)
						const values = icon.getAttribute(questionsOverview.selector.editValues)
						// eslint-disable-next-line no-param-reassign
						valueElement.innerHTML = values ? JSON.parse(values)[editComponent.value] : editComponent.value
						handleSubmitButtonChange(editComponent, valueElement, wrapper, icon)
						M.toast({ html: 'Success', classes: 'background-true' })
					} else if (payload.errorMessage) {
						M.toast({ html: payload.errorMessage, classes: 'background-false' })
					}
				},
			})
		})
		wrapper.appendChild(confirmButton)
		const cancelButton = createButton('fas fa-times', 'red')
		wrapper.appendChild(cancelButton)
		const cancelClick = useEvent(cancelButton, 'click')
		cancelClick.register(() => {
			handleSubmitButtonChange(editComponent, valueElement, wrapper, icon)
		})
		return wrapper
	}

	const handleSubmitButtonChange = (
		editComponent: HTMLInputElement | HTMLSelectElement,
		valueElement: HTMLElement,
		wrapper: HTMLElement,
		icon: HTMLElement,
	) => {
		if (state.activeSelect) {
			state.activeSelect.destroy()
			setState({ activeSelect: null })
		}
		editComponent.replaceWith(valueElement)
		wrapper.replaceWith(icon)
		toggleEditMode(icon)
	}

	const createButton = (iconClassName: string, buttonClassName: string): HTMLButtonElement => {
		const button = document.createElement('BUTTON') as HTMLButtonElement
		button.className = `btn ${buttonClassName}`
		const icon = document.createElement('I') as HTMLElement
		icon.className = iconClassName
		button.appendChild(icon)
		return button
	}

	const createEditComponent = (
		currentValue: string | null,
		possibleValues: string | null,
	): HTMLInputElement | HTMLSelectElement => {
		let editComponent
		if (possibleValues) {
			editComponent = document.createElement('SELECT') as HTMLSelectElement
			// eslint-disable-next-line no-restricted-syntax
			const parsedJSON = JSON.parse(possibleValues)
			for (const key in parsedJSON) {
				const option = document.createElement('OPTION') as HTMLOptionElement
				option.value = key
				option.innerText = parsedJSON[key]
				option.selected = key === currentValue
				editComponent.append(option)
			}
		} else {
			editComponent = document.createElement('INPUT') as HTMLInputElement
			editComponent.type = 'text'
			if (currentValue != null) {
				editComponent.value = currentValue
			}
		}
		editComponent.className = 'meta-edit-input'
		return editComponent
	}

	const initTargetDatePickers = () => {
		const { targetDatePickers } = state
		if (!targetDatePickers) return
		M.Datepicker.init(targetDatePickers, { format: 'yyyy-mm-dd' })
	}

	const handleCreateTargetClick = () => {
		const { createTargetButtons } = state
		if (!createTargetButtons) return
		for (let i = 0; i < createTargetButtons.length; i++) {
			const createTargetButton = createTargetButtons[i]
			const click = useEvent(createTargetButton, 'click')
			click.register(() => {
				const targetElement = createTargetButton.closest<HTMLElement>(questionsOverview.selector.targetContainer)
				if (!targetElement) return
				const noTargetInfo = getNoTargetInfo(targetElement)
				const targetEdit = getTargetEdit(targetElement)
				if (!targetEdit || !noTargetInfo) return
				addClass(noTargetInfo, 'hidden')
				removeClass(targetEdit, 'hidden')
			})
		}
	}

	const handleSaveTargetClick = () => {
		const { saveTargetButtons } = state
		if (!saveTargetButtons) return
		for (let i = 0; i < saveTargetButtons.length; i++) {
			const saveTargetButton = saveTargetButtons[i]
			const click = useEvent(saveTargetButton, 'click')
			click.register(() => {
				const targetEditElement = saveTargetButton.closest<HTMLElement>(questionsOverview.selector.targetEditContainer)
				if (!targetEditElement) return
				const targetValueElement = getTargetValueSelector(targetEditElement)
				const targetDateElement = getTargetDatePickersSelector(targetEditElement, false) as HTMLInputElement
				const targetValue = validateInput(targetValueElement)
				const dateValue = validateInput(targetDateElement)
				if (!targetValue || !dateValue) return
				// @ts-ignore
				$.nette.ajax({
					method: 'POST',
					url: saveTargetButton.getAttribute(questionsOverview.selector.handle),
					data: {
						targetValue,
						dateValue,
						targetId: targetEditElement.getAttribute(questionsOverview.selector.targetId),
					},
					success: (payload: any) => {
						if (payload.success) {
							targetEditElement.setAttribute(questionsOverview.selector.targetId, payload.id)
							M.toast({ html: 'Success', classes: 'background-true' })
							const deleteButton = getDeleteTargetButtonsSelector(targetEditElement, false) as HTMLElement
							if (deleteButton) removeClass(deleteButton, 'disabled')
						} else if (payload.errorMessage) {
							M.toast({ html: payload.errorMessage, classes: 'background-false' })
						}
					},
				})
			})
		}
	}
	const handleDeleteTargetClick = () => {
		const { deleteTargetButtons } = state
		if (!deleteTargetButtons) return
		for (let i = 0; i < deleteTargetButtons.length; i++) {
			const deleteTargetButton = deleteTargetButtons[i]
			const click = useEvent(deleteTargetButton, 'click')
			click.register(() => {
				const targetEditElement = deleteTargetButton.closest<HTMLElement>(
					questionsOverview.selector.targetEditContainer,
				)
				if (!targetEditElement) return
				// @ts-ignore
				$.nette.ajax({
					method: 'POST',
					url: deleteTargetButton.getAttribute(questionsOverview.selector.handle),
					data: {
						targetId: targetEditElement.getAttribute(questionsOverview.selector.targetId),
					},
					success: (payload: any) => {
						if (payload.success) {
							const targetElement = deleteTargetButton.closest<HTMLElement>(questionsOverview.selector.targetContainer)
							if (!targetElement) return
							const noTargetInfo = getNoTargetInfo(targetElement)
							const targetEdit = getTargetEdit(targetElement)
							if (!targetEdit || !noTargetInfo) return
							removeClass(noTargetInfo, 'hidden')
							addClass(targetEdit, 'hidden')
							clearTargetForm(targetEdit)
							addClass(deleteTargetButton, 'disabled')
						} else if (payload.errorMessage) {
							M.toast({ html: payload.errorMessage, classes: 'background-false' })
						}
					},
				})
			})
		}
	}

	const clearTargetForm = (formContainer: HTMLElement) => {
		const targetValueElement = getTargetValueSelector(formContainer)
		const targetDateElement = getTargetDatePickersSelector(formContainer, false) as HTMLInputElement
		targetValueElement.value = ''
		targetDateElement.value = ''
	}

	const handleInitSelectors = () => {
		setState({
			...getQuestionsOverviewSelectors(),
		})
	}

	const init = () => {
		handleInitSelectors()
		initCollapsible()
		initLanguageButton()
		initExportButton()
		initTargetDatePickers()
		handleEditIconClick()
		handleCreateTargetClick()
		handleSaveTargetClick()
		handleDeleteTargetClick()
	}

	return {
		init,
	}
}
