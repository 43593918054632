export const constant = {
	selector: {
		tagOptionsHandler: 'data-tag-options-handler',
		tagOption: 'js-tag-option',
		appealGridAction: 'js-appeal-action',
		appealLink: 'data-appeal-link',
		appealButtonColor: 'data-appeal-button-color',
		appealResolveAction: 'js-appeal-resolve-action',
	},
}
