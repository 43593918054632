import $ from 'jquery'
import { modifier } from 'constant'
import { useEvent, useState } from 'hooks'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const EditMode = (() => {
	const { state, setState } = useState()

	const handleActivation = (scope: HTMLElement) => {
		const click = useEvent(scope, 'click')
		const id = scope.getAttribute(constant.selector.handler)
		if (!id) return

		click.register(() => {
			const targets = selectors.getTargets(id)
			for (let i = 0, { length } = targets; i < length; i++) {
				const target = targets[i]
				cn.addClass(target, modifier.editable)
			}
			setState({ [id]: true })
		})
	}

	const handleDeactivation = (scope: HTMLElement) => {
		const click = useEvent(scope, 'click')
		const id = scope.getAttribute(constant.selector.handler)
		const url = scope.getAttribute(constant.selector.url)
		if (!id || !url) return

		click.register(() => {
			const targets = selectors.getTargets(id)
			for (let i = 0, { length } = targets; i < length; i++) {
				const target = targets[i]
				cn.removeClass(target, modifier.editable)
			}
			setState({ [id]: false })

			// @ts-ignore
			$.nette.ajax({
				method: 'get',
				url,
			})
		})
	}

	const handleInputChange = (scope: HTMLElement) => {
		const change = useEvent<Event>(scope, 'change')
		const url = scope.getAttribute(constant.selector.url)
		if (!url) return

		change.register(({ e }) => {
			const { value } = e.target as HTMLSelectElement | HTMLInputElement

			// @ts-ignore
			$.nette.ajax({
				method: 'post',
				url,
				dataType: 'json',
				data: {
					answerId: value,
				},
			})
		})
	}

	const initInput = () => {
		const input = selectors.getInput()
		if (!input) return

		handleInputChange(input)
	}

	const handleClickPen = (scope: HTMLElement, url: string, itemId: string) => {
		const click = useEvent<MouseEvent>(scope, 'click')
		const id = scope.getAttribute(constant.selector.id)
		const value = scope.getAttribute(constant.selector.value)
		const before = scope.getAttribute(constant.selector.before)

		click.register(({ e }) => {
			if (!state[itemId]) {
				return
			}

			const checkbox = selectors.getDeactivationInput()
			const { target } = e
			if (!checkbox || !target) return

			// @ts-ignore
			if (checkbox.checked && target.type !== 'checkbox') {
				checkbox.checked = false
			}

			// @ts-ignore
			$.nette.ajax({
				method: 'post',
				url,
				dataType: 'json',
				data: {
					id: id && parseFloat(id),
					value,
					before: before && before.trim(),
				},
				complete: () => {
					initInput()
				},
			})
		})
	}

	const handleTarget = (scope: HTMLElement) => {
		const id = scope.getAttribute(constant.selector.target)
		if (!id) return

		const handler = selectors.getTargetHandler(id, true)
		if (!handler) return

		const edit = selectors.getEdit(scope)
		const url = handler.getAttribute(constant.selector.url)
		if (!url || !edit) return

		handleClickPen(edit, url, id)
	}

	const initTarget = () => {
		const targets = selectors.getAllTargets()
		for (let i = 0, { length } = targets; i < length; i++) {
			const target = targets[i]
			handleTarget(target)
		}
	}

	const initHandler = () => {
		const handlers = selectors.getHandlers()

		for (let i = 0, { length } = handlers; i < length; i++) {
			const handler = handlers[i]
			const active = handler.getAttribute(constant.selector.active)
			if (!active) continue

			if (active === 'true') {
				handleActivation(handler)
			}

			if (active === 'false') {
				handleDeactivation(handler)
			}
		}
	}

	const init = () => {
		initHandler()
		initTarget()
	}

	return {
		init,
	}
})()
