import { constant } from './constant'
import { selectors } from './selector'

export const Size = (() => {
	const fixHeights = () => {
		const targets = selectors.getAllHeightTargets()
		const groups = {} as { [key: string]: HTMLElement[] }

		for (let i = 0, { length } = targets; i < length; i++) {
			const t = targets[i]
			const groupName = t.getAttribute(constant.selector.heightTarget)

			if (!groupName) continue
			if (!groups[groupName]) {
				groups[groupName] = []
			}
			groups[groupName].push(t)
		}

		const groupsKeys = Object.keys(groups)
		for (let i = 0, { length } = groupsKeys; i < length; i++) {
			const gk = groupsKeys[i]
			const g = groups[gk]

			if (!g) continue
			const groupLength = g.length
			const max = Math.max(...g.map(item => item.offsetHeight))
			for (let j = 0; j < groupLength; j++) {
				const item = g[j]

				if (!item) continue
				item.setAttribute('style', `height: ${max}px`)
			}
		}
	}

	const init = () => {
		fixHeights()
	}

	return {
		init,
		fixHeights,
		constant,
		selectors,
	}
})()
