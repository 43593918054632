import { useState } from 'hooks'
import { getFeedbackTypesIconsEditSelectors } from 'selectors'

type State = {
	container?: HTMLElement
	iconsSelect?: HTMLElement
}

export const FeedbackTypesIconsEdit = () => {
	const { state, setState } = useState<State>()

	const initTypesEditIcons = () => {
		const { container, iconsSelect } = state

		if (!container) return
		if (!iconsSelect) return

		const parent = iconsSelect.parentElement
		const options = parent.querySelectorAll('.select-dropdown li')
		for (let i = 0; i <= options.length; i++) {
			if (options[i]) {
				const spanCode = options[i].innerHTML
				const iconCode = options[i].querySelectorAll('span')[0].innerHTML
				options[i].innerHTML = '<span class="icon-preview right fa">&#x' + iconCode + ';</span>' + spanCode
			}
		}
	}

	const init = () => {
		setState({ ...getFeedbackTypesIconsEditSelectors() })

		initTypesEditIcons()
	}

	return {
		init,
	}
}
