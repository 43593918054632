import M from 'materialize-css'
import { addClass, removeClass } from './class'

export const validateInput = (input: HTMLInputElement) => {
	if (!input.value) {
		addClass(input, 'invalid')
		return null
	}
	removeClass(input, 'invalid')
	return input.value
}

export const validateSelect = (select: HTMLSelectElement) => {
	if (!select.value) {
		const selectElement = M.FormSelect.getInstance(select).input
		addClass(selectElement, 'invalid')
		return null
	}
	removeClass(select, 'invalid')
	return select.value
}
