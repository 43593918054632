import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getAllHeightTargets = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.heightTarget },
		true,
	)

export const selectors = {
	getAllHeightTargets,
}
