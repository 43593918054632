import $ from 'jquery'
import { debounce } from 'debounce'
import tippy from 'tippy.js'
import {hideAll} from 'tippy.js';

import { createSelector, getCodeToggleSwitch, getMasterCheckbox } from 'selectors'
import { useEvent } from 'hooks'
import { module } from 'constant'
import { toggleCheckboxes, toggleCodes } from './crossTable'

const { selector } = module.crosstable
const tippySelector = '.question-trigger'
const submenuSelector = 'data-template'

interface StringObject {
	[key: string]: string
}

export const CrossTablePopups = () => {
	const init = (isAjax?: boolean) => {
		if (!isAjax) {
			tippy(tippySelector, {
				content(reference) {
					const id = reference.getAttribute(submenuSelector)
					const template = document.getElementById(id)
					return template.innerHTML
				},
				allowHTML: true,
				placement: 'right',
				theme: 'light-border',
				arrow: true,
				distance: 10,
				interactive: true,
				trigger: 'click',
				onMount(instance) {
					const { popper } = instance
					handleValuesToggle(popper)
					handleMasterCheckboxClick(popper)
					handleValuesChange(popper)
				},
			})
		}
	}

	const handleValuesToggle = (scope: HTMLElement) => {
		const codeToggleSwitch = getCodeToggleSwitch(scope)
		if (!codeToggleSwitch) return null
		const change = useEvent<MouseEvent>(codeToggleSwitch, 'change')
		change.register(() => {
			toggleCodes(scope)
		})
	}

	const handleMasterCheckboxClick = (scope: HTMLElement) => {
		const masterCheckbox = getMasterCheckbox(scope)
		if (!masterCheckbox) return null
		const change = useEvent<MouseEvent>(masterCheckbox, 'change')
		change.register(() => {
			toggleCheckboxes(scope, masterCheckbox.checked)
		})
	}

	const isValuesCallValid = (valuesContainer: HTMLElement) => {
		return (
			valuesContainer &&
			valuesContainer.getAttribute(selector.addValuesHandler) &&
			valuesContainer.getAttribute(selector.questionId)
		)
	}

	const handleValuesChange = (scope: HTMLElement) => {
		const valuesContainer = createSelector<HTMLElement>({ scope, selector: `${selector.addValuesHandler}` })
		if (!isValuesCallValid(valuesContainer)) return
		const change = useEvent<MouseEvent>(valuesContainer, 'change')
		const changeClickDisableGenerate = useEvent<MouseEvent>(valuesContainer, 'change')
		const url = valuesContainer.getAttribute(selector.addValuesHandler)
		const questionId = valuesContainer.getAttribute(selector.questionId)
		const questionName = valuesContainer.getAttribute(selector.questionName)
		const questionType = scope.parentElement.parentElement.getAttribute(selector.questionType)

		changeClickDisableGenerate.register(
			function() {
				const generateButton = Array.from(document.querySelectorAll('.flex-20-pc')).pop()
				if (!generateButton) return;
				generateButton.style.backgroundColor = "#f6b2b533";
				generateButton.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';
			}
		);

		change.register(
			debounce(function() {
				hideAll()
				$.nette.ajax({
					url,
					method: 'POST',
					data: { questionId, questionType, values: getCheckboxesValues(scope), questionName },
				})
			}, 1000),
		)
	}

	const getCheckboxesValues = (scope: HTMLElement): Record<string, any> => {
		const checkboxes = createSelector<NodeListOf<HTMLInputElement>>(
			{ scope, selector: selector.valueCheckbox },
			true,
		)
		const values: StringObject = {}
		for (let i = 0; i < checkboxes.length; i++) {
			const checkbox = checkboxes[i]
			const { value } = checkbox
			if (checkbox.checked) {
				values[value] = value
			}
		}
		return values
	}

	return {
		init,
	}
}
