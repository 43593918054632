import { module, modifier } from 'constant'
import { resizerSelectors } from 'selectors'
import { cn, sum, number } from 'utils'

declare global {
	interface Window {
		Highcharts: any
	}
}

type InitProps = {
	onInit?: (item: HTMLElement) => void
}

const {
	resizer: { selector },
} = module

const HIGHCHARTS_CONTAINER = '.highcharts-container'

export const Charts = (() => {
	const getCharts = () => window.Highcharts.charts

	const afterUpdate = (updateCallback?: () => void) => {
		return new Promise(res => {
			if (!window.Highcharts) {
				console.error('Highcharts missing!')
				return
			}
			const { charts } = window.Highcharts

			if (!charts) return
			const { length } = charts
			const promises = []

			for (let i = 0; i < length; i++) {
				const chart = charts[i]
				promises.push(chartPromise(chart, 'afterUpdate'))
			}

			Promise.all(promises).then(() => {
				if (updateCallback) updateCallback()
				res()
			})
		})
	}

	const chartPromise = (eventTarget: any, eventName: string, callback?: () => void) => {
		return new Promise(resolve => {
			const handleEvent = () => {
				window.Highcharts.removeEvent(eventTarget, eventName, handleEvent)
				resolve()
				if (callback) callback()
			}

			window.Highcharts.addEvent(eventTarget, eventName, handleEvent)
		})
	}

	const getMinus = (scope: HTMLElement) => {
		const minuses = resizerSelectors.getHeightMinus(scope)
		let r = 0

		if (!minuses) return r

		for (let i = 0, { length } = minuses; i < length; i++) {
			const minus = minuses[i]
			const { marginTop, marginBottom } = window.getComputedStyle(minus)
			r -= minuses[i].offsetHeight + number.getNumberByPx(marginTop) + number.getNumberByPx(marginBottom)
		}

		return r
	}

	const update = async (updateCallback?: () => void) => {
		if (!window.Highcharts) {
			console.error('Highcharts missing!')
			return
		}
		const { charts } = window.Highcharts

		afterUpdate(updateCallback)

		if (!charts) return
		const { length } = charts

		for (let i = 0; i < length; i++) {
			const chart = charts[i]
			if (chart && !document.body.contains(chart.container)) {
				charts[i] = undefined
			}
		}

		for (let i = 0; i < length; i++) {
			if (typeof charts[i] === 'undefined') continue
			const container = charts[i].renderTo

			if (!container) continue
			const itemBlock = container.closest(selector.itemBlock)
			const itemHead = resizerSelectors.getItemHead(itemBlock)
			const itemFooter = resizerSelectors.getItemFooter(itemBlock)

			if (!itemBlock) continue
			const { paddingLeft, paddingRight } = window.getComputedStyle(itemBlock)

			charts[i].update({
				chart: {
					width:
						itemBlock.offsetWidth -
						(paddingLeft ? parseFloat(paddingLeft) : 0) -
						(paddingRight ? parseFloat(paddingRight) : 0),
					height: sum.getSumByArgs(
						itemBlock.offsetHeight,
						itemHead ? -itemHead.offsetHeight : 0,
						itemFooter ? -itemFooter.offsetHeight : 0,
						getMinus(itemBlock),
					),
				},
			})
		}
	}

	const init = async ({ onInit }: InitProps) => {
		const charts = document.querySelectorAll(HIGHCHARTS_CONTAINER)

		if (!charts) return
		const { length } = charts

		for (let i = 0; i < length; i++) {
			const item = charts[i] as HTMLElement

			if (!item) continue
			await new Promise(res => {
				setTimeout(() => {
					cn.addClass(item, modifier.initialized)
					if (onInit) onInit(item)
					res()
				}, 20)
			})
		}
	}

	return {
		update,
		init,
		afterUpdate,
		getCharts,
	}
})()
