import { Setup } from './setup'

export default () => {
	const init = () => {
		const setup = Setup()
		setup.apply()
	}

	return {
		init,
	}
}
