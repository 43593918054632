import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getTagOption = () => createSelector<HTMLElement>({ selector: `.${selector.tagOption}` })

const getAppealGridActions = () =>
	createSelector(
		{ selector: `.${selector.appealGridAction}` },
		true,
	) as NodeListOf<HTMLButtonElement>

const getAppealResolveActions = () =>
	createSelector(
		{ selector: `.${selector.appealResolveAction}` },
		true,
	) as NodeListOf<HTMLButtonElement>

export const selectors = {
	getTagOption,
	getAppealGridActions,
	getAppealResolveActions,
}
