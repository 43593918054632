import { module } from 'constant'
import { createSelector } from 'selectors'

const {
	hierarchy,
	conditionBuilder,
	feedbackCategories,
	textAnalytics,
	feedbackTypesIconsEdit,
	questionsOverview,
	feedbackWorkflows,
	exportDefinition,
} = module.creator

const getHierarchySelector = () => createSelector<HTMLElement>({ selector: hierarchy.selector.context })

const getQuestionnaireSelectSelector = (scope: HTMLElement) =>
	createSelector<HTMLSelectElement>({ scope, selector: hierarchy.selector.questionnaireSelect })

const getQuestionnaireColumnsContainerSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: hierarchy.selector.questionnaireColumnsContainer })

const getHierarchyColumnsContainerSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: hierarchy.selector.hierarchyColumnsContainer })

const getHierarchyNameInputSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: hierarchy.selector.hierarchyNameInput })

const getHierarchySubmitButtonSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: hierarchy.selector.submitButton })

const getConditionsBuilder = () => createSelector<HTMLSelectElement>({ selector: conditionBuilder.selector.own })

const getQuestionnaireBuilderSelectSelector = () =>
	createSelector<HTMLSelectElement>({ selector: conditionBuilder.selector.questionnaireSelect })

const getQuestionnaireBuilderSelectHandleSelector = () =>
	createSelector<HTMLElement>({ selector: conditionBuilder.selector.showBuilderHandle })

const getGlobalConditionsSelectSelector = () =>
	createSelector<HTMLSelectElement>({ selector: conditionBuilder.selector.globalConditionsSelect })

const getGlobalConditionsSelectHandleSelector = () =>
	createSelector<HTMLElement>({ selector: conditionBuilder.selector.globalConditionsSelectHandle })

const getBlockConditionSelectSelector = () =>
	createSelector<HTMLSelectElement>({ selector: conditionBuilder.selector.blockConditionSelect })

const getBlockConditionSelectHandleSelector = () =>
	createSelector<HTMLElement>({ selector: conditionBuilder.selector.blockConditionSelectHandle })

const getFeedbackCategoriesSortableContainerSelector = () =>
	createSelector<HTMLElement>({ selector: feedbackCategories.selector.own })

const getFeedbackCategoriesSortableBodySelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: feedbackCategories.selector.sortableBody })

const getFeedbackCategoriesTypeSelectSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: feedbackCategories.selector.typeSelect })

const getFeedbackCategoriesSortableRowsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: feedbackCategories.selector.sortableRows },
		true,
	)

const getFeedbackCategoriesReorderCategoriesHandleSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: feedbackCategories.selector.reorderCategoriesHandle })

const getScopeSelector = () => createSelector<HTMLElement>({ selector: textAnalytics.selector.scope })

const getTextQuestionContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: textAnalytics.selector.textQuestionContainer })

const getTAQuestionContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: textAnalytics.selector.textAnalyticsQuestionContainer })

const getConfigKeyButtonSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: textAnalytics.selector.configKeyButton })

const getApiKeyInputSelector = (scope?: HTMLElement) =>
	createSelector<HTMLInputElement>({ scope, selector: textAnalytics.selector.apiKeyInput })

const getTAQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: textAnalytics.selector.taQuestion },
		true,
	)

const getTAQuestionCheckboxSelector = (scope?: HTMLElement) =>
	createSelector<HTMLInputElement>({ scope, selector: textAnalytics.selector.checkedCheckbox })

const getTActiveSwitch = (scope?: HTMLElement) =>
	createSelector<HTMLInputElement>({ scope, selector: textAnalytics.selector.taActiveSwitch })

const getTADomainSelect = (scope?: HTMLElement) =>
	createSelector<HTMLInputElement>({ scope, selector: textAnalytics.selector.domainSelect })

const getTAQuestionRadiosSelector = (scope?: HTMLElement) =>
	createSelector<HTMLInputElement>({ scope, selector: textAnalytics.selector.checkedRadioButtons })

const getFeedbackWorkflowsSortableContainerSelector = () =>
	createSelector<HTMLElement>({ selector: feedbackWorkflows.selector.own })

const getFeedbackWorkflowsSortableBodySelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: feedbackWorkflows.selector.sortableBody })

const getFeedbackWorkflowsSelectSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: feedbackWorkflows.selector.workflowsSelect })

const getFeedbackWorkflowsSortableRowsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: feedbackWorkflows.selector.sortableRows },
		true,
	)

const getFeedbackWorkflowsReorderCategoriesHandleSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: feedbackWorkflows.selector.reorderStatesHandle })

export const getTASelectors = () => {
	const scope = getScopeSelector()

	if (!scope) return null
	return {
		scope,
		textQuestionContainer: getTextQuestionContainerSelector(),
		domainSelect: getTADomainSelect(),
		taActiveSwitch: getTActiveSwitch(),
		taQuestionContainer: getTAQuestionContainerSelector(),
		apiKeyInput: getApiKeyInputSelector(),
		configKeyButton: getConfigKeyButtonSelector(),
	}
}

export const getTAQuestions = () => {
	const scope = getTAQuestionContainerSelector()

	if (!scope) return null
	return getTAQuestionsSelector(scope)
}

export const getCheckedCheckbox = (question: HTMLElement) => {
	return getTAQuestionCheckboxSelector(question)
}

export const getTAQuestionRadios = (question: HTMLElement) => {
	return getTAQuestionRadiosSelector(question)
}

export const getHierarchySelectors = () => {
	const scope = getHierarchySelector()
	if (!scope) return null

	return {
		questionnaireSelect: getQuestionnaireSelectSelector(scope),
		questionnaireColumnsContainer: getQuestionnaireColumnsContainerSelector(scope),
		hierarchyColumnsContainer: getHierarchyColumnsContainerSelector(scope),
		hierarchyNameInput: getHierarchyNameInputSelector(scope),
		hierarchySubmitButton: getHierarchySubmitButtonSelector(scope),
	}
}

export const getHierarchyColumns = () => {
	const scope = getHierarchySelector()
	if (!scope) return null
	const hierarchyColumnsContainer = getHierarchyColumnsContainerSelector(scope)
	if (!hierarchyColumnsContainer) return null

	return createSelector<NodeListOf<HTMLElement>>(
		{
			scope: hierarchyColumnsContainer,
			selector: hierarchy.selector.hierarchyItem,
		},
		true,
	)
}

export const getHierarchyItemWrappers = (scope: HTMLElement) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{
			scope,
			selector: hierarchy.selector.hierarchyItemWrapper,
		},
		true,
	)
}

export const getItemIcon = (item: HTMLElement) => {
	return createSelector<HTMLElement>({ scope: item, selector: hierarchy.selector.icon })
}

export const getHierarchyColumnsContainer = () => {
	const scope = getHierarchySelector()
	if (!scope) return null
	return getHierarchyColumnsContainerSelector(scope)
}

export const getConditionBuilderSelectors = () => {
	return {
		conditionsBuilder: getConditionsBuilder(),
		questionnaireSelect: getQuestionnaireBuilderSelectSelector(),
		showBuilderHandle: getQuestionnaireBuilderSelectHandleSelector(),
		globalConditionsSelect: getGlobalConditionsSelectSelector(),
		globalConditionsSelectHandle: getGlobalConditionsSelectHandleSelector(),
		blockConditionSelect: getBlockConditionSelectSelector(),
		blockConditionSelectHandle: getBlockConditionSelectHandleSelector(),
	}
}

export const getFeedbackCategoriesSelectors = () => {
	const scope = getFeedbackCategoriesSortableContainerSelector()
	if (!scope) return null

	return {
		sortableContainer: scope,
		sortableBody: getFeedbackCategoriesSortableBodySelector(scope),
		typeSelect: getFeedbackCategoriesTypeSelectSelector(scope),
		reorderCategoriesHandle: getFeedbackCategoriesReorderCategoriesHandleSelector(scope),
	}
}

export const getSortableRows = () => {
	const scope = getFeedbackCategoriesSortableBodySelector()
	if (!scope) return null

	return getFeedbackCategoriesSortableRowsSelector(scope)
}

const getFeedbackTypesIconsEditContainerSelector = () =>
	createSelector<HTMLElement>({ selector: feedbackTypesIconsEdit.selector.container })

const getFeedbackTypesIconsEditIconsSelectSelector = (item: HTMLElement) => {
	return createSelector<HTMLElement>({ scope: item, selector: feedbackTypesIconsEdit.selector.iconsSelect })
}

export const getFeedbackTypesIconsEditSelectors = () => {
	const scope = getFeedbackTypesIconsEditContainerSelector()
	if (!scope) return null

	return {
		container: scope,
		iconsSelect: getFeedbackTypesIconsEditIconsSelectSelector(scope),
	}
}

const getQuestionsOverviewSelector = () => {
	return createSelector<HTMLElement>({ selector: questionsOverview.selector.questionsOverview })
}
const getQuestionsOverviewCollapsibleSelector = (scope: HTMLElement) => {
	return createSelector<HTMLElement>({ scope, selector: questionsOverview.selector.questionsOverviewCollapsible })
}

const getQuestionsOverviewLangButtonSelector = (scope: HTMLElement) => {
	return createSelector<HTMLElement>({ scope, selector: questionsOverview.selector.languageButton })
}

const getQuestionsOverviewExportButtonSelector = (scope: HTMLElement) => {
	return createSelector<HTMLElement>({ scope, selector: questionsOverview.selector.exportButton })
}

export const getMetadataEditIconsSelector = (scope: HTMLElement) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: questionsOverview.selector.metadataEdit },
		true,
	)
}

export const getTargetDatePickersSelector = (scope: HTMLElement, more = true) => {
	return createSelector<NodeListOf<HTMLInputElement> | HTMLInputElement>(
		{ scope, selector: questionsOverview.selector.targetDatePicker },
		more,
	)
}

export const getTargetValueSelector = (scope: HTMLElement) => {
	return createSelector<HTMLInputElement>({ scope, selector: questionsOverview.selector.targetValue })
}

export const getCreateTargetButtonsSelector = (scope: HTMLElement) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: questionsOverview.selector.createTargetButton },
		true,
	)
}

export const getSaveTargetButtonsSelector = (scope: HTMLElement) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: questionsOverview.selector.saveTargetButton },
		true,
	)
}

export const getDeleteTargetButtonsSelector = (scope: HTMLElement, more = true) => {
	return createSelector<NodeListOf<HTMLElement> | HTMLElement>(
		{ scope, selector: questionsOverview.selector.deleteTargetButton },
		more,
	)
}

export const getQuestionsOverviewSelectors = () => {
	const scope = getQuestionsOverviewSelector()
	if (!scope) return null

	return {
		questionsOverviewCollapsible: getQuestionsOverviewCollapsibleSelector(scope),
		languageButton: getQuestionsOverviewLangButtonSelector(scope),
		exportButton: getQuestionsOverviewExportButtonSelector(scope),
		metadataEditIcons: getMetadataEditIconsSelector(scope),
		targetDatePickers: getTargetDatePickersSelector(scope),
		createTargetButtons: getCreateTargetButtonsSelector(scope),
		saveTargetButtons: getSaveTargetButtonsSelector(scope),
		deleteTargetButtons: getDeleteTargetButtonsSelector(scope),
	}
}

export const getValueElementOfMetadataItem = (metadataItem: HTMLElement): HTMLElement => {
	return createSelector({ scope: metadataItem, selector: questionsOverview.selector.metadataValue })
}

export const getEditInfoElement = (collapsibleItem: HTMLElement): HTMLElement => {
	return createSelector({ scope: collapsibleItem, selector: questionsOverview.selector.editInfoElement })
}

export const getNoTargetInfo = (targetElement: HTMLElement): HTMLElement => {
	return createSelector({ scope: targetElement, selector: questionsOverview.selector.noTargetContainer })
}

export const getTargetEdit = (targetElement: HTMLElement): HTMLElement => {
	return createSelector({ scope: targetElement, selector: questionsOverview.selector.targetEditContainer })
}

export const getFeedbackWorkflowsSelectors = () => {
	const scope = getFeedbackWorkflowsSortableContainerSelector()
	if (!scope) return null

	return {
		sortableContainer: scope,
		sortableBody: getFeedbackWorkflowsSortableBodySelector(scope),
		workflowsSelect: getFeedbackWorkflowsSelectSelector(scope),
		reorderStatesHandle: getFeedbackWorkflowsReorderCategoriesHandleSelector(scope),
	}
}

export const getWorkflowsSortableRows = () => {
	const scope = getFeedbackWorkflowsSortableBodySelector()
	if (!scope) return null

	return getFeedbackWorkflowsSortableRowsSelector(scope)
}

export const getExportDefinitionSelectors = () => {
	const scope = getExportDefinitionContainerSelector()
	if (!scope) return null

	return {
		container: scope,
		checkAllTranslation: getExportDefinitionCheckAllTranslationSelector(scope),
		checkAllDisplay: getExportDefinitionCheckAllDisplaySelector(scope),
		translateCheckboxes: getExportDefinitionTranslateCheckboxesSelector(scope),
		displayCheckboxes: getExportDefinitionDisplayCheckboxesSelector(scope),
		questionnairesSelect: getExportDefinitionQuestionnairesSelectSelector(scope),
	}
}

const getExportDefinitionContainerSelector = () =>
	createSelector<HTMLElement>({ selector: exportDefinition.selector.container })

const getExportDefinitionCheckAllTranslationSelector = (item: HTMLElement) => {
	return createSelector<HTMLElement>({ scope: item, selector: exportDefinition.selector.checkAllTranslation })
}

const getExportDefinitionCheckAllDisplaySelector = (item: HTMLElement) => {
	return createSelector<HTMLElement>({ scope: item, selector: exportDefinition.selector.checkAllDisplay })
}

const getExportDefinitionTranslateCheckboxesSelector = (scope?: HTMLElement) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: exportDefinition.selector.translateCheckboxes },
		true,
	)
}

const getExportDefinitionDisplayCheckboxesSelector = (scope?: HTMLElement) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: exportDefinition.selector.displayCheckboxes },
		true,
	)
}

const getExportDefinitionQuestionnairesSelectSelector = (item: HTMLElement) => {
	return createSelector<HTMLElement>({ scope: item, selector: exportDefinition.selector.questionnairesSelect })
}
