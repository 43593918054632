export const contextMenu = {
	selector: {
		own: '.js-context-menu',
		blockButton: '.js-context-menu-block-button',
		wrapperButton: '.js-context-menu-wrapper-button',
		id: 'data-context-menu-id',
		parentId: 'data-context-menu-parent-id',
	},
	style: {
		own: 'context-menu',
		switch: 'context-menu__switch',
		button: 'context-menu__button',
		list: 'context-menu__list',
		item: 'context-menu__item',
		action: 'context-menu__action',
		icon: 'context-menu__icon',
		iconWrap: 'context-menu__icon-wrap',
	},
}
