import { BlockBuilder, ContextMenu, Resize, Sizes } from 'modules'
import { resizerSelectors } from 'selectors'
import { SortableEvent } from 'sortablejs'
import { useEvent } from 'hooks'

type InitProps = {
	onInit?: () => void
	onResizeComplete?: () => void
	onWindowResize?: () => void
}

export const AbstractResizer = (() => {
	const rsz = Resize()
	const bb = BlockBuilder()

	const initContextMenu = (active = rsz.getActive()) => {
		const items = resizerSelectors.getItems()

		if (!items) return
		const { length } = items
		for (let i = 0; i < length; i++) {
			const item = items[i]
			const contextMenu = ContextMenu()
			contextMenu.init({
				scope: resizerSelectors.getItemBlock(item),
				items: window.editorBlockContextMenu,
				active,
			})
		}
	}

	const windowResize = (callback?: () => void) => {
		const resize = useEvent(window, 'resize')
		let event: any = null

		resize.register(() => {
			clearTimeout(event)
			event = setTimeout(() => {
				if (callback) callback()
			}, 500)
		})
	}

	const { fixHeight } = rsz

	const init = ({ onInit, onResizeComplete, onWindowResize }: InitProps) => {
		bb.init({
			setSortables: rsz.setSortables,
		})

		rsz.init({
			onInit,
			onActivate: () => {
				bb.show()
			},
			onDeactivate: () => {
				bb.hide()
			},
			onResizeActiveChange: active => {
				initContextMenu(active)
			},
			onResizeComplete: () => {
				if (onResizeComplete) onResizeComplete()

				// @ts-ignore
				$.nette.ajax({
					type: 'POST',
					url: window.resizeBlocks,
					data: {
						blocks: rsz.getSizes(),
					},
				})
			},
			onSortChange: (sortableEvent: SortableEvent) => {
				const sortedBlock = sortableEvent.item.attributes['data-resize-id'].value
				let wrapper = ''
				let changedBlocks: Sizes
				const blocks = rsz.getSizes()

				if (blocks[sortedBlock].parent) {
					wrapper = blocks[sortedBlock].parent || ''
					changedBlocks = filterBlocksForWrapper(blocks, wrapper)
				} else {
					changedBlocks = filterBlocksForPage(blocks)
				}

				rsz.makeLoading()
				// @ts-ignore
				$.nette.ajax({
					type: 'POST',
					url: window.sortBlocks,
					data: {
						blocks: changedBlocks,
						wrapper: wrapper,
					},
					complete: () => {
						rsz.destroyLoading()
					},
				})
			},
			onAdd: bb.addBlock,
			onAutoHeight: newSizes => {
				// @ts-ignore
				$.nette.ajax({
					type: 'POST',
					url: window.resizeBlocks,
					data: {
						blocks: newSizes,
					},
				})
			},
		})

		windowResize(onWindowResize)
	}

	const filterBlocksForWrapper = (blocks: Sizes, wrapper: string): Sizes => {
		const filteredBlocks: Sizes = {}

		for (let i = 0; i < Object.keys(blocks).length; i++) {
			const blockId = Object.keys(blocks)[i]
			const blockData = Object.values(blocks)[i]

			if (blockData.parent && blockData.parent === wrapper) {
				filteredBlocks[blockId] = blockData
			}
		}
		return filteredBlocks
	}

	const filterBlocksForPage = (blocks: Sizes): Sizes => {
		const filteredBlocks: Sizes = {}

		const blocksId = Object.keys(blocks)
		for (let i = 0; i < blocksId.length; i++) {
			const blockId = blocksId[i]
			const blockData = Object.values(blocks)[i]

			if (!blockData.parent) {
				filteredBlocks[blockId] = blockData
			}
		}
		return filteredBlocks
	}

	return {
		fixHeight,
		init,
		initContextMenu,
		automaticHeight: rsz.automaticHeight,
		getSizes: rsz.getSizes,
		destroyLoading: rsz.destroyLoading,
		destroyResizerLoading: rsz.destroyResizerLoading,
	}
})()
