import { module } from 'constant'
import { createSelector } from 'selectors'

const {
	resizer: { selector },
} = module

const getActivatorsSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.activator },
		true,
	)

const getContainersSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.own },
		true,
	)

const getWrapsSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.wrap },
		true,
	)

const getItemsSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.item },
		true,
	)

const getLoadingsSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.loading },
		true,
	)

const getHeightMinusSelector = (scope: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.heightMinus },
		true,
	)

const getItemHeadSelector = (scope: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.itemHead })
const getItemFooterSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.itemFooter })

const getItemByIdSelector = (id: string) => createSelector<HTMLElement>({ selector: selector.itemId, value: id })

const getItemBlockSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.itemBlock })

const getItemBlockContentSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.itemBlockContent })

const getItemCoverSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.itemCover })

const getRightPanelsSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.rightPanel },
		true,
	)

const getBottomPanelsSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.bottomPanel },
		true,
	)

const getLinesSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.lines },
		true,
	)

export const getResizerSelectors = () => ({
	containers: getContainersSelector(),
	wrap: getWrapsSelector(),
	items: getItemsSelector(),
	activators: getActivatorsSelector(),
})

export const getLoaders = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.loader },
		true,
	)

export const getLoadings = () => {
	const loadings = getLoadingsSelector()

	if (!loadings) return null
	return loadings
}

export const getItems = () => {
	const items = getItemsSelector()

	if (!items) return null
	return items
}

export const getRightPanels = () => {
	const panels = getRightPanelsSelector()

	if (!panels) return null
	return panels
}

export const getBottomPanels = () => {
	const panels = getBottomPanelsSelector()

	if (!panels) return null
	return panels
}

export const getItemById = (id: string) => {
	const item = getItemByIdSelector(id)

	if (!item) return null
	return item
}

export const getItemHead = (scope: HTMLElement) => {
	if (!scope) return null
	const head = getItemHeadSelector(scope)

	if (!head) return null
	return head
}

export const getItemFooter = (scope: HTMLElement) => {
	if (!scope) return null
	const footer = getItemFooterSelector(scope)

	if (!footer) return null
	return footer
}

export const getItemBlock = (scope: HTMLElement) => {
	if (!scope) return null
	const block = getItemBlockSelector(scope)

	if (!block) return null
	return block
}

export const getItemBlockContent = (scope: HTMLElement) => {
	if (!scope) return null
	const content = getItemBlockContentSelector(scope)

	if (!content) return null
	return content
}

export const getItemCover = (scope: HTMLElement) => {
	if (!scope) return null
	const cover = getItemCoverSelector(scope)

	if (!cover) return null
	return cover
}

export const getHeightMinus = (scope: HTMLElement) => {
	if (!scope) return null
	const minus = getHeightMinusSelector(scope)

	if (!minus) return null
	return minus
}

export const getLines = () => getLinesSelector()
