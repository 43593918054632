export function debounce(func: Function, timeout = 500) {
	let timer: any
	return (...args: any[]) => {
		const next = () => func(...args)
		if (timer) {
			clearTimeout(timer)
		}
		timer = setTimeout(next, timeout)
	}
}
