import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.handler },
		true,
	)

const getTargets = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.target },
		true,
	)

const getSwitchHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.switchHandler },
		true,
	)

const getSwitchTargets = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.switchTarget },
		true,
	)

const getHandler = (id: string) => createSelector<HTMLElement>({ selector: selector.handler, value: id })

const getTarget = (id: string) => createSelector<HTMLElement>({ selector: selector.target, value: id })

const getForms = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.formAction },
		true,
	)

export const selectors = {
	getSwitchHandlers,
	getSwitchTargets,
	getHandlers,
	getHandler,
	getTarget,
	getTargets,
	getForms,
}
