export const fileManager = {
	selector: {
		folder: '.js-fm-folder',
		folderIcon: '.js-fm-folder-icon',
	},
	icon: {
		folderClose: 'fa-folder',
		folderOpen: 'fa-folder-open',
	},
}
