import M from 'materialize-css'

const MODAL_CLASS = '.modal'

const Modal = () => {
	const init = () => {
		M.Modal.init(document.querySelectorAll(MODAL_CLASS))
	}

	return {
		init,
	}
}

export default Modal
