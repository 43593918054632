import { browser } from './$_functions'

export var app = {
	loader: function() {
		browser.ie()
	},
}

window.addEventListener('load', function() {
	// init on load
	app.loader()
})
