import { useEvent, useState } from 'hooks'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const Input = (() => {
	const { state, setState } = useState()

	const handleAutomaticHeight = (t: HTMLElement) => {
		const input = useEvent<InputEvent>(t, 'input')
		const blur = useEvent<Event>(t, 'blur')
		const focus = useEvent<Event>(t, 'focus')

		input.register(() => {
			t.setAttribute('style', `height: ${t.scrollHeight}px !important`)
		})

		focus.register(() => {
			t.setAttribute('style', `height: ${t.scrollHeight}px !important`)
		})

		blur.register(() => {
			t.removeAttribute('style')
		})
	}

	const initAutomaticHeight = () => {
		const { targets } = state

		if (!targets || targets.length === 0) return
		for (let i = 0, { length } = targets; i < length; i++) {
			const t = targets[i]

			if (!t || !cn.hasClass(t, constant.modifier.autoHeight)) continue
			handleAutomaticHeight(t)
		}
	}

	const init = () => {
		setState({ targets: selectors.getTargets() })
		initAutomaticHeight()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
