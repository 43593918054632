import { module } from 'constant'
import { createSelector } from 'selectors'

const { selector } = module.tracking

export const getTrackingCarousels = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.carousel },
		true,
	)

export const getCampaignTrackingSelects = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLInputElement>>(
		{ scope, selector: selector.campaignSelect },
		true,
	)
