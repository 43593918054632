import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getTable = (value: string) => createSelector<HTMLElement>({ selector: selector.target, value })

const getTablesBodies = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.body },
		true,
	)

const getTableRowsById = (id: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.row, value: id },
		true,
	)

const getTableRowsParents = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.rowId },
		true,
	)

const getTableRowsChildren = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.rowParent },
		true,
	)

const getTableRowParentById = (id: string) => createSelector<HTMLElement>({ selector: selector.rowId, value: id })

const getTableRowsParentChildren = (id: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.rowParent, value: id },
		true,
	)

export const selectors = {
	getTablesBodies,
	getTable,
	getTableRowsById,
	getTableRowsParents,
	getTableRowsParentChildren,
	getTableRowParentById,
	getTableRowsChildren,
}
