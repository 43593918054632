import { modifier } from 'constant'
import { useEvent } from 'hooks'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const ChangeIndicator = (() => {
	const handleChange = (handler: HTMLElement) => {
		const change = useEvent(handler, 'change')
		const id = handler.getAttribute(constant.selector.handler)

		if (!id) return
		const target = selectors.getTarget(id)

		change.register(() => {
			cn.addClass(target, modifier.highlighted)
		})
	}

	const handleClickTarget = (target: HTMLElement) => {
		const click = useEvent(target, 'click')

		click.register(() => {
			cn.removeClass(target, modifier.highlighted)
		})
	}

	const initTargets = () => {
		const targets = selectors.getTargets()

		if (!targets) return
		for (let i = 0, { length } = targets; i < length; i++) {
			handleClickTarget(targets[i])
		}
	}

	const initHandlers = () => {
		const handlers = selectors.getHandlers()

		if (!handlers) return
		for (let i = 0, { length } = handlers; i < length; i++) {
			handleChange(handlers[i])
		}
	}

	const init = () => {
		initHandlers()
		initTargets()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
