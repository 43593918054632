import { modifier } from 'constant'
import { useEvent } from 'hooks'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const Overlay = (() => {
	const showTarget = (id: string, show: boolean) => {
		const target = selectors.getTarget(id)

		if (!target) {
			console.warn(`missing accordion id: ${id}`)
			return
		}

		if (show) {
			cn.addClass(target, modifier.active)
		} else {
			cn.removeClass(target, modifier.active)
		}
	}

	const handleHandler = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const id = handler.getAttribute(constant.selector.handler)
		if (!id) return

		click.register(() => {
			if (cn.hasClass(handler, modifier.active)) {
				cn.removeClass(handler, modifier.active)
				showTarget(id, false)
			} else {
				cn.addClass(handler, modifier.active)
				showTarget(id, true)
			}
		})
	}

	const initOverlayPosition = () => {
		const targets = selectors.getTargets()
		for (let i = 0, { length } = targets; i < length; i++) {
			const target = targets[i]
			if (!target) return

			const id = target.getAttribute(constant.selector.target)
			if (!id) continue

			const moves = selectors.getMoves(id)
			const movesLength = moves.length
			let position = 0

			for (let j = 0; j < movesLength; j++) {
				const move = moves[j]

				if (!move) continue
				position += move.offsetHeight
			}

			target.setAttribute('style', `top: ${position}px`)
		}
	}

	const initHandler = () => {
		const handlers = selectors.getHandlers()

		for (let i = 0, { length } = handlers; i < length; i++) {
			handleHandler(handlers[i])
		}
	}

	const init = () => {
		initHandler()
		initOverlayPosition()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
