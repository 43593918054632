import tippy from 'tippy.js'

export const BlockHelps = (() => {
	const init = () => {
		tippy('.js-block-helps', {
			theme: 'dark',
			allowHTML: true,
			interactive: false,
			trigger: 'click',
			onShow(instance) {
				instance.setContent(instance.reference.dataset.help)
			},
		})

		tippy('.js-block-helps', {
			theme: 'dark',
			allowHTML: false,
			interactive: false,
			onShow(instance) {
				instance.setContent(instance.reference.dataset.tippy)
			},
		})
	}

	return {
		init,
	}
})()
