export const creator = {
	hierarchy: {
		selector: {
			context: '.js-hierarchy',
			questionnaireSelect: '.js-hierarchy-questionnaire-select',
			questionnaireColumnsContainer: '.js-questionnaire-columns-container',
			hierarchyColumnsContainer: '.js-hierarchy-columns-container',
			hierarchyItem: '.js-hierarchy-item',
			hierarchyItemWrapper: '.js-hierarchy-item-wrapper',
			itemIdAttribute: 'data-hierarchy-column-id',
			questionnaireHandler: 'data-handler',
			icon: '.fas.fa-2x.fa-long-arrow-alt-right',
			submitButton: '.js-hierarchy-submit',
			submitHandler: 'data-submit-handler',
			hierarchyNameInput: '#hierarchy-name',
		},
	},
	conditionBuilder: {
		selector: {
			own: '.js-conditions-builder',
			questionnaireSelect: '.js-select-questionnaire',
			showBuilderHandle: 'data-show-builder-handler',
			globalConditionsSelect: '.js-select-global-conditions',
			globalConditionsSelectHandle: 'data-global-conditions-handler',
			blockConditionSelect: '.js-select-block-condition',
			blockConditionSelectHandle: 'data-block-condition-handler',
		},
	},
	textAnalytics: {
		selector: {
			scope: '.text-analytics__wrapper',
			taActiveSwitch: '.js-ta-active',
			textQuestionContainer: '.text-analytics__wrapper__text-questions',
			textAnalyticsQuestionContainer: '.text-analytics__wrapper__ta-questions',
			apiKeyInput: '#api-key',
			configKeyButton: '.config_key_generator',
			checkedCheckbox: 'input[type=checkbox]:checked',
			checkedRadioButtons: '.radio-list input[type=radio]:checked',
			taQuestion: '.question-chip--extended',
			textQuestion: '.question-chip',
			urlAttribute: 'data-url',
			questionIdAttribute: 'data-question-id',
			domainSelect: '.js-ta-domain',
			notSortable: '.js-not-sortable',
		},
		cssClass: {
			invalid: 'invalid',
			taQuestion: 'question-chip--extended',
			textQuestion: 'question-chip',
			ghostClass: 'question-chip--dragged',
		},
	},
	feedbackCategories: {
		selector: {
			own: '.js-feedback-categories-grid',
			sortableBody: 'tbody',
			sortableRows: '.js-sortable-row',
			typeSelect: '.js-type-select',
			reorderCategoriesHandle: 'data-reorder-categories-link-handler',
		},
	},
	feedbackTypesIconsEdit: {
		selector: {
			container: '.js-feedback-types-grid',
			iconsSelect: '.js-feedback-icons-edit',
		},
	},
	questionsOverview: {
		selector: {
			questionsOverview: '.js-questions-overview',
			questionsOverviewCollapsible: '.js-questions-overview-collapsible',
			questionsOverviewCollapsibleItem: '.js-questions-overview-collapsible-item',
			languageButton: '.js-questions-overview-lang-btn',
			exportButton: '.js-questions-overview-export-btn',
			questionOverviewMetadata: '.js-question-overview-meta',
			metadataEdit: '.js-meta-icon',
			metadataItem: '.js-meta-item',
			metadataValue: '.js-meta-value',
			languageValue: 'data-language',
			valueAttribute: 'data-value',
			editValues: 'data-values',
			editInfoElement: '.js-qo-edit-info',
			targetContainer: '.js-target',
			targetDatePicker: '.js-target-datepicker',
			targetValue: '.js-target-value',
			noTargetContainer: '.js-target-no-target',
			targetEditContainer: '.js-target-edit',
			createTargetButton: '.js-create-target',
			saveTargetButton: '.js-save-target',
			deleteTargetButton: '.js-delete-target',
			handle: 'data-handle',
			targetId: 'data-target-id',
		},
	},
	feedbackWorkflows: {
		selector: {
			own: '.js-feedback-workflows-grid',
			sortableBody: 'tbody',
			sortableRows: '.js-sortable-row',
			workflowsSelect: '.js-workflows-select',
			reorderStatesHandle: 'data-reorder-states-link-handler',
		},
	},
	exportDefinition: {
		selector: {
			container: '#js-export-definition-container',
			checkAllTranslation: '#js-check-all-columns-for-translation',
			checkAllDisplay: '#js-check-all-columns-for-display',
			translateCheckboxes: '.js-translate-in-export input[type="checkbox"]',
			displayCheckboxes: '.js-display-in-export input[type="checkbox"]',
			questionnairesSelect: '#js-questionnaires-select',
		},
	},
}
