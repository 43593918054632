import { module } from 'constant'
import { createSelector } from 'selectors'

const { fileManager } = module

const getFoldersSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: fileManager.selector.folder },
		true,
	)

const getFolderIconSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: fileManager.selector.folderIcon })

export const getFileManagerSelectors = () => ({
	folders: getFoldersSelector(),
})

export const getFolderIcon = (scope: HTMLElement) => {
	if (!scope) return null
	const icon = getFolderIconSelector(scope)

	if (!icon) return null
	return icon
}

export const getFolderTree = (scope: HTMLElement, treeId: string) => {
	if (!scope || !treeId) return null
	const tree = createSelector<HTMLElement>({ scope, selector: `#${treeId}` })

	if (!tree) return null
	return tree
}

export const getFolders = (scope: HTMLElement) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: fileManager.selector.folder },
		true,
	)
}
