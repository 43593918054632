import $ from 'jquery'
import Sortable from 'sortablejs'

import { getCrossTableQuestionsSelectors, getItemByQuestionId, getQuestionsSearchInput } from 'selectors'
import { useEvent, useState } from 'hooks'
import { module } from 'constant'
import { handleSearch, toggleCodes } from './crossTable'
import { addClass, hasClass, removeClass } from '../../utils/class'

type State = {
	questionsQuestionsContainer?: HTMLElement
	activeRowsContainer?: HTMLElement
	activeColumnsContainer?: HTMLElement
	codeToggleSwitch?: HTMLElement
	questionsSearchInputContainer?: HTMLElement
}

const { selector } = module.crosstable

export const CrossTableQuestions = () => {
	const { state, setState } = useState<State>()

	const handleAjax = (link: string, name: string) => {
		// @ts-ignore
		$.nette.ajax({
			url: link.replace('replaceParam', name),
			method: 'POST',
		})
	}

	const initSortable = () => {
		const {
			questionsQuestionsContainer,
			activeRowsContainer,
			activeColumnsContainer,
			questionsSearchInputContainer,
		} = state
		if (
			!questionsQuestionsContainer ||
			!activeRowsContainer ||
			!activeColumnsContainer ||
			!questionsSearchInputContainer
		)
			return
		const questionsSearchInput = getQuestionsSearchInput(questionsSearchInputContainer)
		if (!questionsSearchInput) return
		handleSearch(questionsSearchInput, questionsQuestionsContainer, selector.questions.activeRowsContainer)

		const handleColumns = activeColumnsContainer.getAttribute(selector.questions.questionsQuestionHandler)
		const handleRows = activeRowsContainer.getAttribute(selector.questions.questionsQuestionHandler)

		if (!handleColumns || !handleRows) return

		const sortableAvailable = new Sortable(questionsQuestionsContainer, {
			group: {
				name: 'sharedCtQuestionsEditor',
				pull: 'clone',
			},
			animation: 150,
			draggable: 'div',
			filter: selector.selectedQuestionItem,
			sort: false,
			onAdd: e => {
				const { item, target } = e
				const questionId = item.getAttribute(selector.questionId)
				if (!questionId) return
				item.remove()
				const originalItem = getItemByQuestionId(questionId, target)
				if (originalItem) {
					removeClass(originalItem, selector.selectedQuestionItem.replace('.', ''))
				}
			},
			onStart: e => {
				const { item } = e
				if (hasClass(item, selector.attributeQuestion.replace('.', ''))) {
					activeColumnsContainer.style.opacity = '0.15'
					sortableColumnsActive.options.disabled = true
				}
			},
			onEnd: e => {
				const { item } = e
				if (hasClass(item, selector.attributeQuestion.replace('.', ''))) {
					activeColumnsContainer.style.opacity = '1'
					sortableColumnsActive.options.disabled = false
				}
			},
		})

		const sortableRowsActive = new Sortable(activeRowsContainer, {
			group: 'sharedCtQuestionsEditor',
			animation: 150,
			draggable: 'div',
			sort: false,
			onAdd: e => {
				const { clone, item } = e
				addClass(clone, selector.selectedQuestionItem.replace('.', ''))
				removeClass(e.item, selector.searchableItem.replace('.', ''))
				const questionName = item.getAttribute(selector.questionName)
				if (!questionName) return

				handleAjax(handleRows, questionName)
			},
			onRemove: e => {
				const { item } = e

				const questionName = item.getAttribute(selector.questionName)
				if (!questionName) return

				handleAjax(handleRows, questionName)
			},
		})

		const sortableColumnsActive = new Sortable(activeColumnsContainer, {
			group: 'sharedCtQuestionsEditor',
			animation: 150,
			draggable: 'div',
			sort: false,
			onAdd: e => {
				const { clone, item } = e
				addClass(clone, selector.selectedQuestionItem.replace('.', ''))
				removeClass(e.item, selector.searchableItem.replace('.', ''))
				const questionName = item.getAttribute(selector.questionName)
				if (!questionName) return

				handleAjax(handleColumns, questionName)
			},
			onRemove: e => {
				const { item } = e

				const questionName = item.getAttribute(selector.questionName)
				if (!questionName) return

				handleAjax(handleColumns, questionName)
			},
		})

		if (!sortableRowsActive || !sortableAvailable || sortableColumnsActive) return
		setState({
			sortableAvailable,
			sortableAssigned: sortableRowsActive,
			sortableColumnsAssigned: sortableColumnsActive,
		})
	}

	const handleToggleClick = () => {
		const { codeToggleSwitch, questionsQuestionsContainer, activeRowsContainer, activeColumnsContainer } = state
		if (!codeToggleSwitch) return
		const click = useEvent<MouseEvent>(codeToggleSwitch, 'change')
		click.register(() => {
			if (questionsQuestionsContainer && activeRowsContainer && activeColumnsContainer) {
				toggleCodes(questionsQuestionsContainer)
				toggleCodes(activeRowsContainer)
				toggleCodes(activeColumnsContainer)
			}
		})
	}

	const init = () => {
		setState({
			...getCrossTableQuestionsSelectors(),
		})

		initSortable()
		handleToggleClick()
	}

	return {
		init,
	}
}
