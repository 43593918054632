import { module, modifier } from 'constant'
import { useEvent, useState } from 'hooks'
import { contextMenuSelectors } from 'selectors'
import { cn } from 'utils'

type Item = {
	title: string
	event?: (id: number, parentId?: number) => void
	url?: string
	newTab?: boolean
	icon?: string
}

type InitProps = {
	scope?: NodeListOf<HTMLElement> | HTMLElement | null
	items?: Item[]
	active?: boolean
}

type ContextMenuIdType = 'block' | 'wrapper'

type State = InitProps & { contextMenuIdType: ContextMenuIdType }

const CONTEXT_MENU_WIDTH = 200
const CONTEXT_MENU_HEIGHT = 220
const {
	contextMenu: { style, selector },
} = module

let activeContext = false
let outsideClick = (_: MouseEvent) => {}

export const ContextMenu = () => {
	const { state, setState } = useState<State>({
		contextMenuIdType: 'block',
	})

	const createContextSwitch = () => {
		const contextSwitch = document.createElement('div')

		const blockButton = document.createElement('button')
		const blockButtonText = document.createTextNode('Block')

		const wrapperButton = document.createElement('button')
		const wrapperButtonText = document.createTextNode('Wrapper')

		blockButton.setAttribute('type', 'button')
		wrapperButton.setAttribute('type', 'button')

		cn.addClass(contextSwitch, style.switch)
		cn.addClass(blockButton, style.button)
		cn.addClass(blockButton, cn.removeDot(selector.blockButton))
		cn.addClass(blockButton, modifier.active)
		cn.addClass(wrapperButton, style.button)
		cn.addClass(wrapperButton, cn.removeDot(selector.wrapperButton))

		blockButton.appendChild(blockButtonText)
		wrapperButton.appendChild(wrapperButtonText)

		handleSwitch(blockButton, 'block')
		handleSwitch(wrapperButton, 'wrapper')

		contextSwitch.appendChild(blockButton)
		contextSwitch.appendChild(wrapperButton)

		return contextSwitch
	}

	const handleSwitch = (scope: HTMLElement, value: ContextMenuIdType) => {
		const click = useEvent<MouseEvent>(scope, 'click')

		click.register(() => {
			const blockButton = contextMenuSelectors.getContextMenuBlockButton()
			const wrapperButton = contextMenuSelectors.getContextMenuWrapperButton()

			setState({ contextMenuIdType: value })

			if (!blockButton || !wrapperButton) return
			if (state.contextMenuIdType === 'block') {
				cn.addClass(blockButton, modifier.active)
				cn.removeClass(wrapperButton, modifier.active)
			}
			if (state.contextMenuIdType === 'wrapper') {
				cn.removeClass(blockButton, modifier.active)
				cn.addClass(wrapperButton, modifier.active)
			}
		})
	}

	const createMenu = (x: number, y: number, id: number, parentId: number) => {
		if (id === 0) {
			console.warn('CONTEXT MENU: ID is zero!')
		}

		const { items } = state
		const fragment = document.createDocumentFragment()
		const menu = document.createElement('div')
		const list = document.createElement('ul')

		cn.addClass(menu, style.own)
		cn.addClass(menu, cn.removeDot(selector.own))
		cn.addClass(list, style.list)

		menu.setAttribute(
			'style',
			`top: ${
				window.innerHeight + window.scrollY - y < CONTEXT_MENU_HEIGHT
					? window.innerHeight + window.scrollY - CONTEXT_MENU_HEIGHT
					: y
			}px; left: ${x > window.innerWidth - CONTEXT_MENU_WIDTH ? x - CONTEXT_MENU_WIDTH : x}px;`,
		)

		if (items && items.length > 0) {
			const { length } = items
			for (let i = 0; i < length; i++) {
				const item = items[i]

				if (!item) continue
				const newItem = document.createElement('li')
				const newItemTitle = document.createTextNode(item.title)
				let wrapper: HTMLElement

				cn.addClass(newItem, style.item)

				if (item.url) {
					wrapper = document.createElement('a')
					wrapper.setAttribute('href', item.url)
					if (item.newTab) wrapper.setAttribute('target', '_blank')
				} else {
					wrapper = document.createElement('button')
				}

				cn.addClass(wrapper, style.action)

				if (item.event) handleWrapperEvent(wrapper, item.event, id, parentId)

				if (item.icon) {
					const icon = document.createElement('i')
					const iconWrap = document.createElement('i')
					icon.setAttribute('class', `${style.icon} fas fa-${item.icon}`)
					iconWrap.setAttribute('class', style.iconWrap)

					iconWrap.appendChild(icon)
					wrapper.appendChild(iconWrap)
				}

				wrapper.appendChild(newItemTitle)
				newItem.appendChild(wrapper)
				list.appendChild(newItem)
			}
		}

		if (parentId > 0) {
			menu.appendChild(createContextSwitch())
		}

		menu.appendChild(list)
		fragment.appendChild(menu)

		document.body.appendChild(fragment)

		if (menu) {
			outsideClick = (e: MouseEvent) => {
				const target = e.target as HTMLElement

				if (!target) return
				if (!target.closest(selector.own)) {
					destroyMenu()
					document.removeEventListener('click', outsideClick)
				}
			}
			document.addEventListener('click', outsideClick)
		}
	}

	const handleWrapperEvent = (
		scope: HTMLElement,
		event: (id: number, parentId?: number) => void,
		id: number,
		parentId: number,
	) => {
		const click = useEvent<MouseEvent>(scope, 'click')

		click.register(() => {
			if (event) {
				if (state.contextMenuIdType === 'block') event(id, parentId)
				if (state.contextMenuIdType === 'wrapper') event(parentId)
			}
			destroyMenu()
		})
	}

	const destroyMenu = () => {
		const menu = contextMenuSelectors.getContextMenu()

		if (!menu) return
		document.body.removeChild(menu)
	}

	const handleMenu = (scope: NodeListOf<HTMLElement> | HTMLElement) => {
		if (scope instanceof window.NodeList) {
			const { length } = scope

			if (length === 0) return
			for (let i = 0; i < length; i++) {
				const item = scope[i]

				if (!item) continue
				handleMenuEvent(item)
			}
			return
		}

		handleMenuEvent(scope)
	}

	const handleMenuEvent = (item: HTMLElement) => {
		const click = useEvent<MouseEvent>(item, 'contextmenu')
		const { active } = state

		activeContext = active || false

		click.register(({ e }) => {
			if (!activeContext) return
			e.preventDefault()
			const { clientX, clientY } = e
			destroyMenu()
			createMenu(
				clientX + 10,
				clientY + window.pageYOffset,
				parseFloat(item.getAttribute(selector.id) || '0'),
				parseFloat(item.getAttribute(selector.parentId) || '0'),
			)
		})
	}

	const init = ({ scope, items, active }: InitProps) => {
		if (!scope) {
			console.error('CONTEXT MENU: Scope missing!')
			return
		}

		if (!scope) return
		setState({ scope, items, active })

		handleMenu(scope)
	}

	return {
		init,
	}
}
