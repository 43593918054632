export const constant = {
	selector: {
		handler: 'data-m-accordion-handler',
		target: 'data-m-accordion',
		group: 'data-m-accordion-group',
		groupHandler: 'data-m-accordion-group-handler',
		groupHandlerActiveText: 'data-m-accordion-group-active-text',
		groupHandlerInactiveText: 'data-m-accordion-group-inactive-text',
	},
	modifier: {
		handlerRotate: 'data-m-accordion-handler-rotate',
	},
}
