export const browser = {
	//detect IE browsers
	ie: function() {
		if (/(MSIE|Trident\/|Edge\/)/i.test(navigator.userAgent)) $('html').attr('browser', 'IE')
	},
}
/**
 * ELIS functions
 * */
export const show = {
	charts: function() {},
	top: function() {
		$('html, body').animate({ scrollTop: 0 }, 200)
	},
	back: function() {
		window.history.back()
	},
}

export const debounce = function(func, interval) {
	var timeout
	return function() {
		var context = this,
			args = arguments
		var later = function() {
			timeout = null
			func.apply(context, args)
		}
		clearTimeout(timeout)
		timeout = setTimeout(later, interval || 200)
	}
}

export const makeDebounceAjaxCall = debounce(function(value, link) {
	$.nette.ajax({
		type: 'POST',
		dataType: 'json',
		url: link,
		data: {
			code: value,
		},
	})
}, 500)
