import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.handler },
		true,
	)

const getGroup = (value: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.group, value },
		true,
	)

const getGroupsHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.groupHandler },
		true,
	)

const getHandler = (id: string) => createSelector<HTMLElement>({ selector: selector.handler, value: id })

const getTarget = (id: string) => createSelector<HTMLElement>({ selector: selector.target, value: id })

export const selectors = {
	getHandlers,
	getHandler,
	getTarget,
	getGroup,
	getGroupsHandlers,
}
