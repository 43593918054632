import $ from 'jquery'

import { useState } from 'hooks'
import { getExportDefinitionSelectors } from 'selectors'

type State = {
	container?: HTMLElement
	checkAllTranslation?: HTMLElement
	checkAllDisplay?: HTMLElement
	translateCheckboxes?: HTMLElement
	displayCheckboxes?: HTMLElement
	questionnairesSelect?: HTMLSelectElement
}

export const ExportDefinition = () => {
	const { state, setState } = useState<State>()

	const initExportDefinition = () => {
		const { checkAllTranslation, checkAllDisplay } = state
		if (!checkAllTranslation || !checkAllDisplay) return

		checkAllDisplay.addEventListener('click', () => {
			checkUncheckAll('.js-display-in-export', checkAllDisplay.checked)
		})

		checkAllTranslation.addEventListener('click', () => {
			checkUncheckAll('.js-translate-in-export', checkAllTranslation.checked)
		})

		const { questionnairesSelect } = state
		if (!questionnairesSelect) return

		questionnairesSelect.addEventListener('change', () => {
			// @ts-ignore
			$.nette.ajax({
				type: 'POST',
				url: questionnairesSelect.getAttribute('data-getcolumnslink'),
				data: {
					questionnaireId: questionnairesSelect.value,
				},
			})
		})

		const { translateCheckboxes, displayCheckboxes } = state
		if (!translateCheckboxes || !displayCheckboxes) return

		watchCheckedAll(translateCheckboxes, checkAllTranslation)
		watchCheckedAll(displayCheckboxes, checkAllDisplay)
	}

	const checkUncheckAll = (checkboxTypes, checked) => {
		if (!checkboxTypes) return

		const checkboxes = document.querySelectorAll(checkboxTypes + ' input[type="checkbox"]')
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].disabled === true) {
				continue
			}
			checkboxes[i].checked = checked
		}
	}

	const watchCheckedAll = (checkboxes, checkAllCheckbox) => {
		for (let i = 0; i < checkboxes.length; i++) {
			checkboxes[i].addEventListener('click', () => {
				allCheckboxesSelected(checkboxes, checkAllCheckbox)
			})
		}
	}

	const allCheckboxesSelected = (checkboxes, checkAllCheckbox) => {
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked === false) {
				checkAllCheckbox.checked = false
				break
			}
		}
	}

	const init = () => {
		setState({ ...getExportDefinitionSelectors() })

		initExportDefinition()
	}

	return {
		init,
	}
}
