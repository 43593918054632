import { easing, styler, tween, TweenProps } from 'popmotion'

type AnimationProps = {
	el: HTMLElement
	from: TweenProps['from']
	to: TweenProps['to']
	duration?: number
	onComplete?: () => void
}

// export const useAnimation = (el: HTMLElement, from: TweenProps['from'], to: TweenProps['to'], duration = 300) => {
export const useAnimation = ({ el, from, to, duration = 300, onComplete }: AnimationProps) => {
	const newStyler = styler(el)
	tween({
		from,
		to,
		duration,
		ease: easing.easeInOut,
	}).start({
		update: newStyler.set,
		complete: onComplete,
	})
}
