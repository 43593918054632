import { module } from 'constant'
import { createSelector } from 'selectors'

const {
	contextMenu: { selector },
} = module

const getContextMenuSelector = () => createSelector<HTMLElement>({ selector: selector.own })
const getContextMenuBlockButtonSelector = () => createSelector<HTMLElement>({ selector: selector.blockButton })
const getContextMenuWrapperButtonSelector = () => createSelector<HTMLElement>({ selector: selector.wrapperButton })

export const getContextMenu = () => {
	const menu = getContextMenuSelector()

	if (!menu) return null
	return menu
}

export const getContextMenuBlockButton = () => {
	const button = getContextMenuBlockButtonSelector()

	if (!button) return null
	return button
}

export const getContextMenuWrapperButton = () => {
	const button = getContextMenuWrapperButtonSelector()

	if (!button) return null
	return button
}
