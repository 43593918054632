import $ from 'jquery'
import Sortable, { SortableEvent } from 'sortablejs'

import { modifier, module } from 'constant'
import { useState, useEvent } from 'hooks'
import { blockBuilderSelector } from 'selectors'
import { cn } from 'utils'

declare global {
	interface Window {
		addNewBlockLink: string
	}
}

type State = {
	blockBuilder?: HTMLElement
	active: boolean
} & InitProps

type InitProps = {
	onComplete?: () => void
	onEnd?: () => void
	onChange?: () => void
	setSortables?: (newSortables: Sortable[]) => void
}

const {
	blockBuilder: { selector },
} = module

export const BlockBuilder = () => {
	const { state, setState } = useState<State>({
		active: false,
	})

	const handleActivateBuilder = (el: HTMLElement) => {
		const click = useEvent(el, 'click')
		const { blockBuilder } = state

		if (!blockBuilder) return
		click.register(() => {
			if (state.active) {
				cn.removeClass(blockBuilder, modifier.active)
				setState({ active: false })
			} else {
				cn.addClass(blockBuilder, modifier.active)
				setState({ active: true })
			}
		})
	}

	const handleActivateSection = (el: HTMLElement) => {
		const click = useEvent(el, 'click')
		const { blockBuilder } = state

		if (!blockBuilder) return
		const sections = blockBuilderSelector.getBlockBuilderSections(blockBuilder)
		const sectionsActivators = blockBuilderSelector.getBlockBuilderSectionActivator(blockBuilder)

		if (!sections || !sectionsActivators) return
		const sectionsLength = sections.length
		const sectionsActivatorsLength = sectionsActivators.length

		if (sectionsLength !== sectionsActivatorsLength) {
			console.warn('BLOCK BUILDER: Sections and sections activators lengths are not equal!')
			return
		}
		click.register(() => {
			const id = el.getAttribute(selector.sectionActivator)

			if (!id) return
			const section = blockBuilderSelector.getBlockBuilderSectionByValue(blockBuilder, id)

			if (!section) return
			for (let i = 0; i < sectionsLength; i++) {
				const s = sections[i]
				const sa = sectionsActivators[i]

				if (!s) continue
				cn.addClass(s, modifier.hidden)
				cn.removeClass(sa, modifier.active)
			}
			cn.removeClass(section, modifier.hidden)
			cn.addClass(el, modifier.active)
		})
	}

	const addBlock = (e: SortableEvent) => {
		const { onComplete } = state
		const newOrder = []
		const { children } = e.to
		const { dataset } = e.target
		const { templateName } = e.item.dataset
		let wrapperId = '0'

		if (!children) return
		const { length } = children

		if (length === 0) return
		for (let i = 1; i < length; i++) {
			const child = e.to.children[i] as any

			if (!child) continue
			newOrder.push(child.dataset.blockEditor)
		}

		if (dataset && dataset.contextMenuId) {
			wrapperId = dataset.contextMenuId
		}

		// @ts-ignore
		$.nette.ajax({
			type: 'POST',
			url: window.addNewBlockLink,
			data: {
				newOrder,
				templateName,
				wrapperId,
			},
			complete: () => {
				if (onComplete) onComplete()
			},
		})
	}

	const show = () => {
		const { blockBuilder } = state

		if (!blockBuilder) return
		cn.removeClass(blockBuilder, modifier.hidden)
	}

	const hide = () => {
		const { blockBuilder } = state

		if (!blockBuilder) return
		cn.addClass(blockBuilder, modifier.hidden)
	}

	const initSectionActivator = () => {
		const { blockBuilder } = state

		if (!blockBuilder) return
		const activators = blockBuilderSelector.getBlockBuilderSectionActivator(blockBuilder)

		if (!activators) return
		const { length } = activators

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const activator = activators[i]

			if (!activator) return
			handleActivateSection(activator)
		}
	}

	const initAddSortable = () => {
		const { blockBuilder, onChange, onEnd, setSortables } = state

		if (!blockBuilder) return
		const sortable = blockBuilderSelector.getBlockBuilderSortable(blockBuilder)

		if (!sortable) return
		const { length } = sortable

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const item = new Sortable(sortable[i], {
				group: {
					name: 'resizer',
					pull: 'clone',
					put: false,
				},
				animation: 200,
				sort: false,
				revertOnSpill: true,
				onStart: () => {
					cn.removeClass(blockBuilder, modifier.active)
				},
				onChange: () => {
					if (onChange) onChange()
				},
				onEnd: () => {
					cn.addClass(blockBuilder, modifier.active)
					if (onEnd) onEnd()
				},
			})
			if (setSortables) setSortables([item])
		}
	}

	const initSwitcher = () => {
		const { blockBuilder } = state

		if (!blockBuilder) return
		const blockBuilderSwitch = blockBuilderSelector.getBlockBuilderSwitch(blockBuilder)

		if (!blockBuilderSwitch) return
		handleActivateBuilder(blockBuilderSwitch)
	}

	const init = (props: InitProps) => {
		if (props) {
			const { setSortables, onChange, onEnd, onComplete } = props
			setState({ setSortables, onChange, onEnd, onComplete, blockBuilder: blockBuilderSelector.getBlockBuilder() })
		}

		initSwitcher()
		initSectionActivator()
		initAddSortable()
	}

	return {
		init,
		addBlock,
		show,
		hide,
	}
}
