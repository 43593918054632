import $ from 'jquery'

import { module } from 'constant'
import { useEvent, useState } from 'hooks'
import { getConditionBuilderSelectors } from 'selectors'

type State = {
	questionnaireSelect?: HTMLSelectElement
	globalConditionsSelect?: HTMLSelectElement
	blockConditionSelectHandle?: HTMLSelectElement
}

const { selector } = module.creator.conditionBuilder

export const ConditionsBuilder = () => {
	const { state, setState } = useState<State>()

	const handleAjax = (link: string, data: object) => {
		// @ts-ignore
		$.nette.ajax({
			type: 'POST',
			dataType: 'json',
			url: link,
			data: data,
			complete() {
				setState({ ...getConditionBuilderSelectors() })
			},
		})
	}

	const handleSelectQuestionnaire = () => {
		const { questionnaireSelect } = state
		if (!questionnaireSelect) return

		const handle = questionnaireSelect.getAttribute(selector.showBuilderHandle)
		if (!handle) return

		const change = useEvent(questionnaireSelect, 'change')
		change.register(() => {
			const data = { questionnaire: questionnaireSelect.value }
			handleAjax(handle, data)
		})
	}

	const handleSelectBlockCondition = () => {
		const { blockConditionSelect } = state
		if (!blockConditionSelect) return

		const handle = blockConditionSelect.getAttribute(selector.blockConditionSelectHandle)
		if (!handle) return

		const change = useEvent(blockConditionSelect, 'change')
		change.register(() => {
			const data = { conditionId: blockConditionSelect.value }
			handleAjax(handle, data)
		})
	}

	const handleSelectGlobalConditions = () => {
		const { globalConditionsSelect } = state
		if (!globalConditionsSelect) return

		const handle = globalConditionsSelect.getAttribute(selector.globalConditionsSelectHandle)
		if (!handle) return

		const change = useEvent(globalConditionsSelect, 'change')
		change.register(() => {
			const selectedConditions = globalConditionsSelect.querySelectorAll('option:checked')
			if (!selectedConditions) return

			const conditionsLength = selectedConditions.length
			const conditions = []
			for (let i = 0; i < conditionsLength; i++) {
				const condition = selectedConditions[i]
				if (!condition) continue

				const value = condition.getAttribute('value')
				if (!value) continue

				conditions.push(value)
			}

			const data = { conditions: conditions }
			handleAjax(handle, data)
		})
	}

	const init = () => {
		setState({ ...getConditionBuilderSelectors() })
		handleSelectQuestionnaire()
		handleSelectGlobalConditions()
		handleSelectBlockCondition()
	}

	return {
		init,
	}
}
