import { useEvent } from 'hooks'

import { constant } from './constant'
import { selectors } from './selector'

export const Checkbox = (() => {
	const handleChange = (scope: HTMLElement) => {
		const change = useEvent<any>(scope, 'change')

		change.register(() => {
			const streamId = scope.getAttribute(constant.selector.stream)
			if (!streamId) return

			const stream = selectors.getStream(streamId)
			let check = true
			for (let i = 0, { length } = stream; i < length; i++) {
				const item = stream[i] as HTMLInputElement
				item.checked = i === 0 ? true : check
				if (item === scope) {
					check = false
				}
			}
		})
	}

	const initStreams = () => {
		const streams = selectors.getStreams()
		if (!streams) return

		const groupedStreams = {} as { [key: string]: HTMLElement[] }
		for (let i = 0, { length } = streams; i < length; i++) {
			const item = streams[i]
			const streamId = item.getAttribute(constant.selector.stream)
			if (!streamId) return
			if (!groupedStreams[streamId]) {
				groupedStreams[streamId] = []
			}
			groupedStreams[streamId].push(item)
		}

		const keys = Object.keys(groupedStreams)
		for (let i = 0, { length } = keys; i < length; i++) {
			const key = keys[i]
			const list = groupedStreams[key]
			if (!list) return

			const listLength = list.length
			for (let j = 0; j < listLength; j++) {
				const item = list[j]
				handleChange(item)
			}
		}
	}

	const init = () => {
		initStreams()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
