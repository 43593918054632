import $ from 'jquery'
import Sortable from 'sortablejs'

import { getCrosstableEditorSelectors } from 'selectors'
import { useEvent, useState } from 'hooks'
import { module } from 'constant'

type State = {
	editorQuestions?: HTMLElement
	editorAvailableQuestions?: HTMLElement
	editorAssignedQuestions?: HTMLElement
	editorQuestionnaireSelect?: HTMLElement
	editorQuestionnaireSubmit?: HTMLElement
}

const { selector } = module.crosstable

export const CrosstableEditor = () => {
	const { state, setState } = useState<State>()

	const handleAjax = (link: string, questionId: string) => {
		const { handleReinitCrosstable } = state
		// @ts-ignore
		$.nette.ajax({
			url: link.replace('-id=0;', `-id=${questionId};`),
			complete: () => {
				if (handleReinitCrosstable) handleReinitCrosstable()
			},
		})
	}

	const initSortable = () => {
		const { editorAvailableQuestions, editorAssignedQuestions } = state

		if (!editorAvailableQuestions || !editorAssignedQuestions) return null
		const handle = editorAssignedQuestions.getAttribute(selector.editorQuestionHandler)

		if (!handle) return null
		const sortableAvailable = new Sortable(editorAssignedQuestions, {
			group: 'sharedCtEditor',
			animation: 150,
			draggable: 'div',
			onAdd: e => {
				const { item } = e
				const questionId = item.getAttribute(selector.editorQuestion)

				if (!questionId) return
				handleAjax(handle, questionId)
			},
			onRemove: e => {
				const { item } = e
				const questionId = item.getAttribute(selector.editorQuestion)

				if (!questionId) return
				handleAjax(handle, questionId)
			},
		})

		const sortableAssigned = new Sortable(editorAvailableQuestions, {
			group: 'sharedCtEditor',
			animation: 150,
			draggable: 'div',
		})

		if (!sortableAssigned || !sortableAvailable) return null
		return {
			sortableAvailable,
			sortableAssigned,
		}
	}

	const initQuestionnaireSelectEvents = () => {
		const { editorQuestionnaireSelect } = state

		if (!editorQuestionnaireSelect) return
		handleQuestionnaireChange(editorQuestionnaireSelect)
	}

	const handleQuestionnaireChange = (el: HTMLElement) => {
		const change = useEvent(el, 'change')
		const { editorQuestionnaireSubmit } = state

		if (!editorQuestionnaireSubmit) return
		change.register(() => {
			editorQuestionnaireSubmit.click()
		})
	}

	const init = () => {
		setState({
			...getCrosstableEditorSelectors(),
		})

		initQuestionnaireSelectEvents()
		initSortable()
	}

	return {
		init,
	}
}
