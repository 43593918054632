export const crosstable = {
	selector: {
		own: '.js-ct',
		content: '.js-ct-content',
		loader: '.js-ct-loader',
		static: '.js-ct-static',
		rows: '.js-ct-rows',
		headRow: '.js-ct-row-head',
		headCol: 'data-ct-col-head',
		headColSplitItem: 'data-ct-col-head-split-item',
		staticRow: 'data-ct-row-static',
		staticCol: 'data-ct-col-static',
		staticColSplit: 'data-ct-static-col-split',
		row: 'data-ct-row',
		col: 'data-ct-col',
		colSplit: 'data-ct-col-split',
		colSplitItem: 'data-ct-col-split-item',
		questionName: 'data-ct-question-name',
		modalActiveRows: '.js-ct-modal-active-rows',
		modalActiveCols: '.js-ct-modal-active-columns',
		modalLegend: '.js-ct-modal-legend',
		modalConfig: '.js-ct-modal-config',
		modalConfigQuestions: '.js-ct-modal-config-questions',
		modalConfigQuestionsItem: '.js-ct-modal-config-questions-item',
		searchInput: '.js-ct-search-input',
		searchableItem: '.js-ct-modal-config-questions-item-searchable',
		searchableAttributes: 'data-searchable-attrs',
		searchableQuestionName: 'data-tooltip',
		editorQuestions: '.js-ct-editor-questions',
		editorAvailableQuestions: '.js-ct-editor-available-questions',
		editorAssignedQuestions: '.js-ct-editor-assigned-questions',
		editorQuestionnaireSelect: '.js-ct-editor-questionnaire-select',
		editorQuestionnaireSubmit: '.js-ct-editor-questionnaire-submit',
		editorQuestion: 'data-ct-editor-question',
		editorQuestionHandler: 'data-ct-editor-question-handler',
		questionsFilterContainer: '.js-ct-questions-filter-container',
		questionsQuestionsContainer: '.js-ct-questions-questions-container',
		questionItem: '.js-ct-questions-item',
		questionId: 'data-ct-question-id',
		attributeQuestion: '.js-ct-is-attribute',
		questionType: 'data-ct-question-type',
		selectedQuestionItem: '.crosstable-tab-container__item--selected',
		codeItem: '.js-ct-item-code',
		valuesCodeToggleSwitch: '.js-ct-values-container',
		valuesContainer: '.js-ct-values-container',
		codeToggleSwitch: '.js-ct-code-toggle',
		masterCheckbox: '.js-ct-master-checkbox',
		valueCheckbox: '.js-ct-value-checkbox',
		addValuesHandler: 'data-ct-values-handler',
		lastHeaderColumn: '.js-ct-last-head-col',
		filter: {
			filterTab: '.js-ct-tab-filter',
			activeQuestionsContainer: '.js-ct-active-filter-container',
			filterQuestionHandler: 'data-ct-filter-question-handler',
			filterSearchSelector: '.js-ct-search-input-filter',
			filterSearchContainer: '.js-ct-questions-filter-container',
		},
		questions: {
			tab: '.js-ct-tab-questions',
			activeRowsContainer: '.js-ct-active-questions-container-rows',
			activeColumnsContainer: '.js-ct-active-questions-container-columns',
			questionsQuestionHandler: 'data-ct-questions-question-handler-questions',
			questionsSearchSelector: '.js-ct-search-input-questions',
			questionsContainer: '.js-ct-questions-questions-container',
			questionsSearchInputContainer: '.js-ct-questions-search-container',
		},
		settings: {
			tab: '.js-ct-tab-settings',
			weightedSwitch: '.js-ct-weighted-switch',
			unweightedCountSwitch: '.js-ct-unweighted-count-switch',
			totalSwitch: '.js-ct-total-switch',
			subtotalSwitch: '.js-ct-subtotal-switch',
			saveHandler: 'data-handler',
		},
	},
	size: {
		font: 12,
		colWidth: 90,
		padding: 8,
	},
}
