import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getTargets = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: `.${selector.target}` },
		true,
	)

export const selectors = {
	getTargets,
}
