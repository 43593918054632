export const constant = {
	selector: {
		handler: 'data-m-control-handler',
		target: 'data-m-control',
		switchHandler: 'data-m-control-switch-handler',
		switchTarget: 'data-m-control-switch',
		formAction: 'data-m-form-action',
		formFieldMultiple: 'data-m-form-field-multiple',
	},
}
