import $ from 'jquery'
import Sortable from 'sortablejs'

import { getCrossTableFilterSelectors, getFilterSearchInput, getItemByQuestionId } from 'selectors'
import { useEvent, useState } from 'hooks'
import { module } from 'constant'
import { handleSearch, toggleCodes } from './crossTable'
import { addClass, removeClass } from '../../utils/class'
import M from 'materialize-css'

type State = {
	questionsFilterContainer?: HTMLElement
	activeQuestionsContainer?: HTMLElement
	codeToggleSwitch?: HTMLElement
}

const { selector } = module.crosstable

export const CrossTableFilter = () => {
	const { state, setState } = useState<State>()

	const handleAjax = (link: string, questionId: string) => {
		// @ts-ignore
		$.nette.ajax({
			url: link,
			method: 'POST',
			data: { questionId },
		})
	}

	const initSortable = () => {
		const { questionsFilterContainer, activeQuestionsContainer } = state
		if (!questionsFilterContainer || !activeQuestionsContainer) return null
		const filterSearchInput = getFilterSearchInput(questionsFilterContainer)
		if (!filterSearchInput) return null
		handleSearch(filterSearchInput, questionsFilterContainer, selector.filter.activeQuestionsContainer)
		const handleRemove = activeQuestionsContainer.getAttribute(selector.filter.filterQuestionHandler)
		const handleAdd = activeQuestionsContainer.getAttribute(selector.addValuesHandler)

		if (!handleRemove || !handleAdd) return null
		const sortableAvailable = new Sortable(questionsFilterContainer, {
			group: {
				name: 'sharedCtEditor',
				pull: 'clone',
			},
			animation: 150,
			draggable: 'div',
			filter: selector.selectedQuestionItem,
			sort: false,
			onAdd: e => {
				const { item, target } = e
				const questionId = item.getAttribute(selector.questionId)
				if (!questionId) return
				item.remove()
				const originalItem = getItemByQuestionId(questionId, target)
				if (originalItem) {
					removeClass(originalItem, selector.selectedQuestionItem.replace('.', ''))
				}
				handleAjax(handleRemove, questionId)
			},
		})

		const sortableFilterActive = new Sortable(activeQuestionsContainer, {
			group: 'sharedCtEditor',
			animation: 150,
			draggable: 'div',
			onAdd: e => {
				const { item, clone } = e
				addClass(clone, selector.selectedQuestionItem.replace('.', ''))
				const questionId = item.getAttribute(selector.questionId)
				if (!questionId) return
				handleAjax(handleAdd, questionId)
			},
		})

		if (!sortableFilterActive || !sortableAvailable) return null
		return {
			sortableAvailable,
			sortableAssigned: sortableFilterActive,
		}

		// // @ts-ignore
		// $.nette.ext({
		// 	complete: ({ reinitAll }: { [key: string]: any }) => {
		// 		if (!reinitAll) return
		//
		// 	},
		// })
	}

	const handleToggleClick = () => {
		const { codeToggleSwitch, questionsFilterContainer } = state
		if (!codeToggleSwitch) return null
		const click = useEvent<MouseEvent>(codeToggleSwitch, 'change')
		click.register(() => {
			if (questionsFilterContainer) {
				toggleCodes(questionsFilterContainer)
			}
		})
	}

	const init = () => {
		setState({
			...getCrossTableFilterSelectors(),
		})

		initSortable()
		handleToggleClick()
	}

	return {
		init,
	}
}
