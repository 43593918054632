import { modifier } from 'constant'
import { useEvent } from 'hooks'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const Activator = (() => {
	const handleAction = (scope: HTMLElement) => {
		const click = useEvent(scope, 'click')
		const id = scope.getAttribute(constant.selector.handler)
		if (!id) return

		const target = selectors.getTarget(id)
		if (!target) return

		click.register(() => {
			if (cn.hasClass(target, modifier.active)) {
				cn.removeClass(target, modifier.active)
				cn.removeClass(scope, modifier.active)
			} else {
				cn.addClass(target, modifier.active)
				cn.addClass(scope, modifier.active)
			}
		})
	}

	const initHandlers = () => {
		const handlers = selectors.getAllHandlers()

		for (let i = 0, { length } = handlers; i < length; i++) {
			const handler = handlers[i]
			handleAction(handler)
		}
	}

	const init = () => {
		initHandlers()
	}

	return {
		init,
	}
})()
