// TODO: DEPRECATED -> Refactor

// @ts-ignore
import * as ConditionsBuilder from '_elis/conditionsBuilder'
// @ts-ignore
import { alerts } from '_elis/alerts'
// @ts-ignore
import { handleBarAnimation } from '_elis/bar_animation'

export const Deprecated = (() => {
	const init = (isAjax?: boolean) => {
		ConditionsBuilder.init(isAjax)
		alerts.alertsEvents()
		handleBarAnimation()
	}

	return {
		init,
	}
})()
