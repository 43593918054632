import $ from 'jquery'
import Sortable, { SortableEvent } from 'sortablejs'

import { cn } from 'utils'
import { useEvent, useState } from 'hooks'
import { module } from 'constant'
import { getTASelectors, getTAQuestions, getCheckedCheckbox, getTAQuestionRadios } from 'selectors'
import { validateInput, validateSelect } from '../../utils/inputs'

const { selector, cssClass } = module.creator.textAnalytics

type taQuestion = {
	active: boolean
	questionId: number
	type: number
}

type State = {
	scope?: HTMLElement
	taActiveSwitch?: HTMLElement
	domainSelect?: HTMLSelectElement
	textQuestionContainer?: HTMLElement
	taQuestionContainer?: HTMLElement
	taQuestions?: NodeListOf<HTMLElement>
	apiKeyInput?: HTMLInputElement
	configKeyButton?: HTMLElement
}

export const TextAnalytics = () => {
	const { setState, state } = useState<State>()

	const onDraggableChange = (event: SortableEvent) => {
		if (event.from !== event.to) {
			const itemElement = event.item
			itemElement.classList.toggle(cssClass.textQuestion)
			itemElement.classList.toggle(cssClass.taQuestion)
		}
	}

	const validateQuestions = (taQuestions: NodeListOf<HTMLElement>): boolean => {
		const { taQuestionContainer } = state
		if (!taQuestionContainer) return false
		const nodeLength = taQuestions.length
		if (nodeLength === 0) {
			cn.addClass(taQuestionContainer, cssClass.invalid)
			return false
		}
		cn.removeClass(taQuestionContainer, cssClass.invalid)
		let isValid = true
		for (let i = 0; i < taQuestions.length; i++) {
			const isAnyRadioChecked = getTAQuestionRadios(taQuestions[i])
			if (!isAnyRadioChecked) {
				cn.addClass(taQuestions[i], cssClass.invalid)
				isValid = false
			} else {
				cn.removeClass(taQuestions[i], cssClass.invalid)
			}
		}
		return isValid
	}

	const getQuestionsValues = (taQuestions: NodeListOf<HTMLElement>): taQuestion[] => {
		let values: taQuestion[] = []
		for (let i = 0; i < taQuestions.length; i++) {
			const questionId = taQuestions[i].getAttribute(selector.questionIdAttribute)
			const active = +!!getCheckedCheckbox(taQuestions[i])
			const type = getTAQuestionRadios(taQuestions[i]).value
			values = [...values, { questionId, active, type }] as taQuestion[]
		}
		return values
	}

	const handleConfigButtonClick = () => {
		const { configKeyButton, taActiveSwitch } = state
		if (!configKeyButton) return
		const click = useEvent(configKeyButton, 'click')
		click.register(() => {
			const { apiKeyInput, domainSelect } = state
			const taQuestions = getTAQuestions()
			if (!apiKeyInput || !taQuestions || !taActiveSwitch || !domainSelect) return
			const isValid = validateInput(apiKeyInput) && validateQuestions(taQuestions) && validateSelect(domainSelect)
			if (!isValid) return
			// @ts-ignore
			$.nette.ajax({
				method: 'POST',
				url: configKeyButton.getAttribute(selector.urlAttribute),
				data: {
					questions: getQuestionsValues(taQuestions),
					active: +!!getCheckedCheckbox(taActiveSwitch),
					api_key: apiKeyInput.value,
					text_analytics_module: domainSelect.value,
				},
			})
		})
	}

	const handleSortableInit = () => {
		const { textQuestionContainer, taQuestionContainer } = state
		if (!textQuestionContainer || !taQuestionContainer) return
		const sortableTextQuestions = new Sortable(textQuestionContainer, {
			group: 'ta-shared-group',
			animation: 150,
			ghostClass: cssClass.ghostClass,
			onEnd: onDraggableChange,
		})

		const sortableTAQuestions = new Sortable(taQuestionContainer, {
			group: 'ta-shared-group',
			animation: 150,
			ghostClass: cssClass.ghostClass,
			filter: selector.notSortable,
			onEnd: onDraggableChange,
		})

		setState({ sortableTextQuestions, sortableTAQuestions })
	}

	const handleInitSelectors = () => {
		setState({
			...getTASelectors(),
		})
	}
	const init = () => {
		handleInitSelectors()
		handleConfigButtonClick()
		handleSortableInit()
	}

	return {
		init,
	}
}
