import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.handler },
		true,
	)

const getAllTargets = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.target },
		true,
	)

const getTargets = (value: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.target, value },
		true,
	)

const getEdit = (scope: HTMLElement) => createSelector<HTMLElement>({ selector: `.${selector.edit}`, scope })

const getInput = () => createSelector<HTMLElement>({ selector: `.${selector.input}` })

const getTargetHandler = (id: string, active: boolean) =>
	document.querySelector(
		`[${constant.selector.handler}="${id}"][${constant.selector.url}][${constant.selector.active}="${active}"]`,
	)

const getDeactivationInput = () => createSelector<HTMLInputElement>({ selector: `.${selector.deactivation}` })

export const selectors = {
	getHandlers,
	getTargets,
	getAllTargets,
	getTargetHandler,
	getEdit,
	getInput,
	getDeactivationInput,
}
