import { useEvent } from 'hooks'

import { constant } from './constant'
import { selectors } from './selector'

export const Copy = (() => {
	const handleHandler = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const id = handler.getAttribute(constant.selector.handler)

		if (!id) return
		const target = selectors.getTarget(id)

		if (!target) return
		click.register(() => {
			const value = target.getAttribute(constant.selector.value)

			if (!value) return
			window.navigator.clipboard.writeText(value)
		})
	}

	const initHandler = () => {
		const handler = selectors.getHandlers()

		for (let i = 0, { length } = handler; i < length; i++) {
			const h = handler[i]

			if (!h) continue
			handleHandler(h)
		}
	}

	const init = () => {
		initHandler()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
