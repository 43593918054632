import { module } from 'constant'
import { createSelector } from 'selectors'

const {
	blockBuilder: { selector },
} = module

const getBlockBuilderSelector = () => createSelector<HTMLElement>({ selector: selector.own })
const getBlockBuilderSwitchSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.switch })
const getBlockBuilderSectionSelector = (scope: HTMLElement, value: string) =>
	createSelector<HTMLElement>({ scope, selector: selector.section, value })
const getBlockBuilderSectionsSelector = (scope: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.section },
		true,
	)
const getBlockBuilderSectionActivatorSelector = (scope: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.sectionActivator },
		true,
	)
const getBlockBuilderSortableSelector = (scope: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.sortable },
		true,
	)

export const getBlockBuilder = () => {
	const bb = getBlockBuilderSelector()

	if (!bb) return null
	return bb
}

export const getBlockBuilderSwitch = (scope: HTMLElement) => {
	if (!scope) return null
	const bbSwitch = getBlockBuilderSwitchSelector(scope)

	if (!bbSwitch) return null
	return bbSwitch
}

export const getBlockBuilderSectionByValue = (scope: HTMLElement, value: string) => {
	if (!scope) return null
	const section = getBlockBuilderSectionSelector(scope, value)

	if (!section) return null
	return section
}

export const getBlockBuilderSections = (scope: HTMLElement) => {
	if (!scope) return null
	const section = getBlockBuilderSectionsSelector(scope)

	if (!section) return null
	return section
}

export const getBlockBuilderSectionActivator = (scope: HTMLElement) => {
	if (!scope) return null
	const activator = getBlockBuilderSectionActivatorSelector(scope)

	if (!activator) return null
	return activator
}

export const getBlockBuilderSortable = (scope: HTMLElement) => {
	if (!scope) return null
	const sortable = getBlockBuilderSortableSelector(scope)

	if (!sortable) return null
	return sortable
}
