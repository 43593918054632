export const constant = {
	selector: {
		target: 'data-m-table',
		body: 'data-m-table-body',
		row: 'data-m-table-row',
		rowId: 'data-m-table-row-id',
		rowParent: 'data-m-table-row-parent',
		lazyLink: 'data-m-table-lazy-link',
	},
}
