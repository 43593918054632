import Sortable from 'sortablejs'
import $ from 'jquery'

import { useState } from 'hooks'
import { getFeedbackCategoriesSelectors, getSortableRows } from 'selectors'
import { module } from 'constant'

type State = {
	sortableContainer?: HTMLElement
	sortableBody?: HTMLElement
	typeSelect?: HTMLSelectElement
}

const { selector } = module.creator.feedbackCategories

export const FeedbackCategories = () => {
	const { state, setState } = useState<State>()

	const initSortable = () => {
		const { typeSelect, sortableRowsHandle } = state

		if (sortableRowsHandle) {
			sortableRowsHandle.destroy()
			setState({ sortableRowsHandle: null })
		}

		if (!typeSelect || typeSelect.value.length === 0) return

		handleSortable()
	}

	const handleSortable = () => {
		const { typeSelect, sortableContainer, sortableBody } = state
		if (!typeSelect || !sortableContainer || !sortableBody) return

		const sortableRowsHandle = new Sortable(sortableBody, {
			onUpdate: () => {
				const sortableRows = getSortableRows()
				if (sortableRows.length === 0) return

				let priority = sortableRows.length
				let items = {}
				for (let i = 0; i < sortableRows.length; i++) {
					items[sortableRows[i].dataset.id] = priority
					priority--
				}

				// @ts-ignore
				$.nette.ajax({
					method: 'post',
					url: typeSelect.getAttribute(selector.reorderCategoriesHandle),
					data: { items },
				})
			},
		})

		setState({ sortableRowsHandle })
	}

	const init = () => {
		setState({ ...getFeedbackCategoriesSelectors() })

		initSortable()
	}

	return {
		init,
	}
}
