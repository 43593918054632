import tippy from 'tippy.js'

import { Control, Copy, ChangeIndicator, Input, Checkbox, EditMode } from 'component'

import { constant } from './constant'
import { selectors } from './selector'

export const AlertDetail = (() => {
	const handleClickTagOption = (tagOption: HTMLElement) => {
		const handle = () => {
			tagOption.removeEventListener('click', handle)
		}

		tagOption.addEventListener('click', handle)
	}

	const initTagOptions = () => {
		tippy(`[${constant.selector.tagOptionsHandler}]`, {
			content: `<i class="far fa-thumbs-down m-tag-option ${constant.selector.tagOption}"></i>`,
			theme: 'light',
			allowHTML: true,
			interactive: true,
			onShown: () => {
				const tagOption = selectors.getTagOption()
				if (!tagOption) return

				handleClickTagOption(tagOption)
			},
		})
	}

	const init = () => {
		Control.init()
		Copy.init()
		Input.init()
		ChangeIndicator.init()
		Checkbox.init()
		EditMode.init()
		initTagOptions()
	}

	return {
		init,
	}
})()
