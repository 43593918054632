import $ from 'jquery'

import { useEvent, useState } from 'hooks'
import { module } from 'constant'
import { getCrossTableSettingsSelectors } from 'selectors'

type State = {
	weightedSwitch?: HTMLInputElement
	unweightedCountSwitch?: HTMLInputElement
	totalSwitch?: HTMLInputElement
	subtotalSwitch?: HTMLInputElement
}

const { selector } = module.crosstable

export const CrossTableSettings = () => {
	const { state, setState } = useState<State>()

	const handleWeightedSwitchChange = () => {
		const { weightedSwitch } = state
		generalSwitchAction(weightedSwitch)
	}

	const handleUnweightedCountSwitchChange = () => {
		const { unweightedCountSwitch } = state
		generalSwitchAction(unweightedCountSwitch)
	}

	const handleTotalSwitchChange = () => {
		const { totalSwitch } = state
		generalSwitchAction(totalSwitch)
	}

	const handleSubtotalSwitchChange = () => {
		const { subtotalSwitch } = state
		generalSwitchAction(subtotalSwitch)
	}

	const generalSwitchAction = (switchElement?: HTMLInputElement) => {
		if (!switchElement) return null
		const change = useEvent<MouseEvent>(switchElement, 'change')
		change.register(() => {
			$.nette.ajax({
				url: switchElement.getAttribute(selector.settings.saveHandler),
				method: 'POST',
				data: { value: switchElement.checked | 0 },
			})
		})
	}

	const init = () => {
		setState({
			...getCrossTableSettingsSelectors(),
		})
		handleWeightedSwitchChange()
		handleUnweightedCountSwitchChange()
		handleTotalSwitchChange()
		handleSubtotalSwitchChange()
	}

	return {
		init,
	}
}
