import { Hierarchy } from './hierarchy'
import { ConditionsBuilder } from './conditionBuilder'
import { FeedbackCategories } from './feedbackCategories'
import { TextAnalytics } from './textAnalytics'
import { FeedbackTypesIconsEdit } from './feedbackTypesIconsEdit'
import { QuestionsOverview } from './questionsOverview'
import { FeedbackWorkflows } from './feedbackWorkflows'
import { ExportDefinition } from './exportDefinition'

export const Creator = (() => {
	const hierarchy = Hierarchy()
	const conditionBuilder = ConditionsBuilder()
	const feedbackCategories = FeedbackCategories()
	const textAnalytics = TextAnalytics()
	const feedbackTypesIconsEdit = FeedbackTypesIconsEdit()
	const questionsOverview = QuestionsOverview()
	const feedbackWorkflows = FeedbackWorkflows()
	const exportDefinition = ExportDefinition()
	const init = () => {
		hierarchy.init()
		conditionBuilder.init()
		feedbackCategories.init()
		textAnalytics.init()
		feedbackTypesIconsEdit.init()
		questionsOverview.init()
		feedbackWorkflows.init()
		exportDefinition.init()
	}

	return {
		init,
	}
})()
