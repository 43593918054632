import { bp } from './breakpoint'
import { blockBuilder } from './blockBuilder'
import { contextMenu } from './contextMenu'
import { classes } from './classes'
import { crosstable } from './crosstable'
import { drawer } from './drawer'
import { fileManager } from './fileManager'
import { filter } from './filter'
import { header } from './header'
import { menu } from './menu'
import { overlay } from './overlay'
import { resizer } from './resizer'
import { creator } from './creator'
import { tracking } from './tracking'

type Module = {
	bp: typeof bp
	blockBuilder: typeof blockBuilder
	contextMenu: typeof contextMenu
	classes: typeof classes
	crosstable: typeof crosstable
	drawer: typeof drawer
	fileManager: typeof fileManager
	filter: typeof filter
	header: typeof header
	menu: typeof menu
	overlay: typeof overlay
	tracking: typeof tracking
	resizer: typeof resizer
	creator: typeof creator
}

export const module: Module = {
	bp,
	blockBuilder,
	contextMenu,
	classes,
	crosstable,
	drawer,
	fileManager,
	filter,
	header,
	menu,
	overlay,
	resizer,
	creator,
	tracking,
}

export * from './modifier'
