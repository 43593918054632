import $ from 'jquery'
import { modifier } from 'constant'
import { numberHelper } from 'helper'
import { useEvent } from 'hooks'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const Control = (() => {
	const handleHandler = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const id = handler.getAttribute(constant.selector.handler)

		if (!id) return
		const target = selectors.getTarget(id)

		if (!target) return
		click.register(() => {
			if (cn.hasClass(target, modifier.active)) {
				cn.removeClass(target, modifier.active)
				cn.removeClass(handler, modifier.active)
			} else {
				cn.addClass(target, modifier.active)
				cn.addClass(handler, modifier.active)
			}
		})
	}

	const handleSwitchHandler = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const id = handler.getAttribute(constant.selector.switchHandler)
		const targets = selectors.getSwitchTargets()

		click.register(() => {
			for (let i = 0, { length } = targets; i < length; i++) {
				const target = targets[i]
				const targetId = target.getAttribute(constant.selector.switchTarget)

				if (!targetId) continue
				if (targetId === id) {
					cn.addClass(target, modifier.active)
				} else {
					cn.removeClass(target, modifier.active)
				}
			}
		})
	}

	const handleSubmit = (scope: HTMLFormElement) => {
		const submit = useEvent<Event>(scope, 'submit')
		const link = scope.getAttribute(constant.selector.formAction)
		const multiple = scope.getAttribute(constant.selector.formFieldMultiple)
		if (!link) return
		submit.register(({ e }) => {
			e.preventDefault()
			const formData = new window.FormData(scope)
			const values: { [key: string]: string | number } = Array.from(formData.entries()).reduce(
				(rest, item) => {
					const key = item[0]
					const value = item[1]
					if (multiple && multiple.indexOf(key) > -1) {
						if (!rest[key]) {
							rest[key] = []
						}
						return {
							...rest,
							[key]: [...rest[key], numberHelper.isNumber(value) ? parseFloat(String(value)) : value],
						}
					}
					return { ...rest, [key]: numberHelper.isNumber(value) ? parseFloat(String(value)) : value }
				},
				{} as { [key: string]: any },
			)

			// @ts-ignore
			$.nette.ajax({
				method: 'POST',
				url: link,
				dataType: 'json',
				data: {
					values,
				},
			})
		})
	}

	const initForms = () => {
		const forms = selectors.getForms()
		for (let i = 0, { length } = forms; i < length; i++) {
			const form = forms[i] as HTMLFormElement
			handleSubmit(form)
		}
	}

	const initHandler = () => {
		const handler = selectors.getHandlers()

		for (let i = 0, { length } = handler; i < length; i++) {
			const h = handler[i]

			if (!h) continue
			handleHandler(h)
		}
	}

	const initSwitchHandler = () => {
		const handler = selectors.getSwitchHandlers()

		for (let i = 0, { length } = handler; i < length; i++) {
			const h = handler[i]

			if (!h) continue
			handleSwitchHandler(h)
		}
	}

	const init = () => {
		initHandler()
		initSwitchHandler()
		initForms()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
