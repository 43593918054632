import * as M from 'materialize-css'
import $ from 'jquery'
import { useEvent } from '../hooks/useEvent'

let builder

const RULE_VALUE_SELECT = '.rule-value-container select'
const RULES_GROUP_SELECT = '.rules-group-container select'

// eslint-disable-next-line no-shadow
const recursiveAddClassDatepicker = (rules, divRules) => {
	let divId
	for (let i = 0; i < rules.length; i++) {
		if ('filter' in rules[i] && rules[i].filter.type === 'datetime') {
			divId = divRules[rules[i].id] === undefined ? divRules.id : divRules[rules[i].id].id
			builder.find(`#${divId} > div.rule-value-container > input`).addClass('datepicker')
		} else if (rules[i].id.indexOf('group') !== -1) {
			recursiveAddClassDatepicker(rules[i].rules, divRules)
		}
	}
}

const initFormSelects = (selector, target = null) => {
	let elements
	if (typeof selector === 'string') {
		elements = document.querySelectorAll(selector)
	} else {
		elements = selector
	}

	if (!elements) return
	for (let i = 0; i < elements.length; i++) {
		const element = elements[i]
		if (target && element === target) {
			continue
		}
		M.FormSelect.init(element)
	}
}

const initDatepicker = () => {
	const elements = document.querySelectorAll('.datepicker')
	M.Datepicker.init(elements, {
		selectMonths: true,
		format: 'yyyy-mm-dd',
		formatSubmit: 'yyyy-mm-dd',
		firstDay: 1,
	})
}

const saveCondition = () => {
	const conditionName = document.getElementById('conditions-name').value
	const link = document.getElementById('js-conditions-builder-save').getAttribute('data-link')

	const selectQuestionnaire = document.querySelector('.js-select-questionnaire')
	let questionnaire
	if (selectQuestionnaire) {
		questionnaire = selectQuestionnaire.options[selectQuestionnaire.selectedIndex].value
	}

	builder.on('ruleToSQL.queryBuilder.filter', (e, rule) => {
		const sql = e.value
		const columnName = rule.field
		if (typeof sql === 'string' && !e.value.includes('#.')) {
			// eslint-disable-next-line no-param-reassign
			e.value = sql.replace(columnName, `#.${columnName}`)
		}
	})

	$.nette.ajax({
		type: 'POST',
		dataType: 'json',
		url: link,
		data: {
			name: conditionName,
			questionnaire,
			rules: builder.queryBuilder('getRules'),
			sql: builder.queryBuilder('getSQL'),
		},
	})
}

const copyToClipboard = text => {
	const temp = document.createElement('textarea')
	if (!temp) return

	document.body.appendChild(temp)
	temp.value = text
	temp.select()
	document.execCommand('copy')
	temp.remove()
}

const initTooltip = () => {
	const button = document.getElementById('js-conditions-builder-get-sql')
	if (!button) return

	M.Tooltip.init(button)
}

const setDatepicker = () => {
	const model = builder.queryBuilder('getModel')
	const divRules = []
	builder.find('div[id*=qb_*_rule_*]').each((index, value) => {
		divRules[value.id] = value
	})

	recursiveAddClassDatepicker(model.rules, divRules)
	initDatepicker()
}

async function setRules(rules) {
	await builder.queryBuilder('setRules', rules)
	await setDatepicker()
}

export const init = (isAjax = false) => {
	builder = $('.js-conditions-builder')
	if (builder.length === 0) return

	const { queryFilters } = window
	const { queryRules } = window

	builder.queryBuilder({
		filters: queryFilters,
		icons: {
			add_group: 'fas fa-plus',
			add_rule: 'fas fa-plus',
			remove_group: 'fas fa-minus',
			remove_rule: 'fas fa-minus',
		},
		operators: $.fn.queryBuilder.constructor.DEFAULTS.operators.concat([
			{ type: 'less_than_today', optgroup: 'custom', nb_inputs: 0, multiple: false, apply_to: ['date'] },
			{ type: 'greater_than_today', optgroup: 'custom', nb_inputs: 0, multiple: false, apply_to: ['date'] },
		]),
		sqlOperators: {
			less_than_today: { op: '<= CURDATE()' },
			greater_than_today: { op: '>= CURDATE()' },
		},
	})

	builder
		.on('afterCreateRuleInput.queryBuilder', (e, rule) => {
			const valueInput = rule.$el.find('.rule-value-container input')
			if (rule.filter.type === 'datetime') {
				valueInput.addClass('datepicker')
				initDatepicker()
			} else if (valueInput.hasClass('datepicker')) {
				valueInput.datepicker('destroy')
			}
		})
		.on('afterUpdateRuleOperator.queryBuilder', (rule, previousOperator) => {
			const operator = previousOperator.__.operator.type
			const ruleSelect = previousOperator.$el.find(RULE_VALUE_SELECT)

			if (operator === 'in' || operator === 'not_in') {
				ruleSelect.attr('multiple', 'multiple')
				// eslint-disable-next-line no-param-reassign
				previousOperator.filter.multiple = true
			} else if (ruleSelect.prop('multiple') === true) {
				ruleSelect.removeAttr('multiple')
				// eslint-disable-next-line no-param-reassign
				previousOperator.filter.multiple = false
			}
		})
		.on('displayError.queryBuilder.filter', e => {
			M.toast({
				html: `<i class="fa" style="padding-right: 15px;">&#xf12a;</i> ${e.value}`,
				displayLength: 5000,
				classes: 'background-false',
			})
		})
		.on('ruleToSQL.queryBuilder.filter', (e, rule) => {
			if (rule.operator.type === 'less_than_today' || rule.operator.type === 'greater_than_today') {
				e.value += rule.field
			}
		})

	if (queryRules.length !== 0) {
		setRules(queryRules)
		initFormSelects(RULES_GROUP_SELECT)
	}

	if (isAjax) {
		initFormSelects(RULES_GROUP_SELECT)
	}

	$(document).on('click', '.btn[data-add="rule"], .btn[data-add="group"]', () => {
		initFormSelects(RULES_GROUP_SELECT)
	})

	$(document).on('change', RULES_GROUP_SELECT, action => {
		initFormSelects(RULES_GROUP_SELECT, action.target)
	})

	handleCopy()
	handleSave()
	initTooltip()
}

const handleCopy = () => {
	const handler = document.getElementById('js-conditions-builder-get-sql')
	if (!handler) return

	const click = useEvent(handler, 'click')

	click.register(() => {
		const result = builder.queryBuilder('getSQL')
		copyToClipboard(result.sql)
	})
}

const handleSave = () => {
	const handler = document.getElementById('js-conditions-builder-save')
	if (!handler) return

	const click = useEvent(handler, 'click')

	click.register(() => {
		saveCondition()
	})
}
