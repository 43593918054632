export const resizer = {
	selector: {
		own: '.js-resize',
		loader: '.js-resize-loader',
		loading: '.js-resize-loading',
		wrap: '.js-resize-wrap',
		item: '.js-resize-item',
		disable: '.js-resize-disable',
		itemId: 'data-resize-id',
		itemCover: '.js-resize-item-cover',
		itemBlock: '.js-resize-item-block',
		itemBlockContent: '.js-resize-item-block-content',
		itemHead: '.js-resize-item-head',
		itemFooter: '.js-resize-item-footer',
		itemContentWrap: '.js-resize-item-content-wrap',
		parentId: 'data-resize-parent-id',
		width: 'data-resize-w',
		height: 'data-resize-h',
		autoHeight: 'data-resize-auto-h',
		activator: '.js-resize-activator',
		rightPanel: '.js-resize-right-panel',
		bottomPanel: '.js-resize-bottom-panel',
		lines: '.js-resize-lines',
		rowLines: '.js-resize-row-lines',
		heightMinus: '.js-resize-height-minus',
		hasWrapper: 'has-wrapper',
	},
	style: {
		loading: 'blocks-resizer__loading',
		itemCover: 'blocks-resizer__item-cover',
	},
}
