export const hasClass = (el: HTMLElement, className: string) => {
	const { classList } = el
	if (!classList) {
		return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`))
	}
	return el.classList.contains(className)
}

export const addClass = (el: HTMLElement, cn: string) => {
	const { classList } = el
	if (!classList) {
		// eslint-disable-next-line no-param-reassign
		el.className += ` ${cn}`
	} else {
		el.classList.add(cn)
	}
}

export const removeClass = (el: HTMLElement, className: string) => {
	const { classList } = el
	if (!classList) {
		// eslint-disable-next-line no-param-reassign
		el.className = el.className.replace(new RegExp(`(\\s|^)${className}(\\s|$)`), ' ')
	} else {
		el.classList.remove(className)
	}
}

export const removeDot = (c: string) => c.substring(1, c.length)
