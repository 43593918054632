import $ from 'jquery'
import Swal from 'sweetalert2'

import { useEvent } from 'hooks'
import { constant } from './constant'
import { selectors } from './selector'

export const AlertAppeal = (() => {
	const initGridActions = () => {
		const buttons = selectors.getAppealGridActions()
		if (!buttons) return

		for (let i = 0; i < buttons.length; i++) {
			const button = buttons[i]
			if (!button) continue

			handleClickButton(button)
		}
	}

	const handleClickButton = (button: HTMLButtonElement) => {
		const click = useEvent(button, 'click')
		click.register(async () => {
			const buttonText = button.innerText
			const colorClass = button.getAttribute(constant.selector.appealButtonColor)
			const { value: note } = await openModal(buttonText, colorClass)
			if (!note) return

			const link = button.getAttribute(constant.selector.appealLink)
			if (!link) return

			saveValues(link, note)
		})
	}

	const saveValues = (link: string, values: string) => {
		// @ts-ignore
		$.nette.ajax({
			method: 'POST',
			url: link,
			dataType: 'json',
			data: {
				values,
			},
		})
	}

	const openModal = async (text: string, colorClass: string | null) => {
		return Swal.fire({
			confirmButtonText: text,
			customClass: {
				confirmButton: colorClass || '',
			},
			input: 'textarea',
			inputPlaceholder: 'Type your message here...',
			inputAttributes: {
				'aria-label': 'Type your message here',
			},
			// eslint-disable-next-line consistent-return
			inputValidator(inputValue: string): SyncOrAsync<string | null> {
				if (!inputValue) {
					return 'Please fill the reason'
				}
			},
		})
	}

	const init = () => {
		initGridActions()
	}

	return {
		init,
	}
})()
