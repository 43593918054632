const isNumber = (value: any) => {
	if (typeof value === 'string' && value.length === 0) {
		return false
	}
	// eslint-disable-next-line no-restricted-globals
	return !isNaN(value)
}

export const numberHelper = {
	isNumber,
}
