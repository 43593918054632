import { module } from 'constant'
import M from 'materialize-css'
import { getCampaignTrackingSelects, getTrackingCarousels } from '../selectors/tracking'

const { selector } = module.tracking
export const Tracking = (() => {
	const initCarousel = (carousel: HTMLElement) => {
		M.Carousel.init(carousel, {
			onCycleTo(item) {
				window.makeDebounceAjaxCall(item.getAttribute(selector.adId), item.getAttribute(selector.carouselHandle))
			},
		})
	}

	const initCarousels = (isAjax?: boolean) => {
		const carousels = getTrackingCarousels()
		if (!carousels) {
			return
		}

		for (let i = 0; i < carousels.length; i++) {
			const carousel = carousels[i]
			if (!carousel || isAjax) {
				continue
			}
			initCarousel(carousel)
		}
	}

	const triggerCampaignSelectChange = () => {
		const selects = getCampaignTrackingSelects()
		const event = new Event('change')
		for (let i = 0; i < selects.length; i++) {
			selects[i].dispatchEvent(event)
		}
	}
	const init = (isAjax?: boolean) => {
		initCarousels(isAjax)
	}

	return {
		init,
		triggerCampaignSelectChange,
	}
})()
