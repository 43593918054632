import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getAllHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.handler },
		true,
	)

const getTarget = (value: string) => createSelector<HTMLElement>({ selector: selector.target, value })

export const selectors = {
	getAllHandlers,
	getTarget,
}
